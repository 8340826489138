import TC from "../style/TC.module.css";
import { Link, useLocation } from "react-router-dom";

export default function DocumentationItems() {
  const location = useLocation();
  return (
    <main className={TC.mainBody}>
      <h1 className={TC.h1TC}>Documentation</h1>
      {location.pathname === "documentation" ? (
        <p>
          Welcome to AnnoGram! In this document, we'll cover some of the basic
          functionality of the application. You can use the menu on the
          left-hand side to quickly navigate the topics. If you prefer a more
          hands-on approach, try the <Link to="../annogram">demo</Link>.
        </p>
      ) : (
        <p>
          Welcome to AnnoGram! In this document, we'll cover some of the basic
          functionality of the application. You can use the menu on the
          left-hand side to quickly navigate the topics.
        </p>
      )}
      <h2 className={TC.h2TC} id="layout">
        Layout
      </h2>
      <p>
        AnnoGram has four main components: {""}
        <a href="#sidebar">Sidebar</a>; {""}
        <a href="#toolbar">Toolbar</a>; {""}
        <a href="#reader">Reader</a>; {""}
        <a href="#canvas">Canvas</a>.
      </p>
      <h3 className={TC.h3TC} id="sidebar">
        Sidebar
      </h3>
      <p>
        The sidebar is the core of AnnoGram. It stores highlights from the{" "}
        <a href="#reader">reader</a> and allows the user to drag and drop those
        highlights onto the <a href="#canvas">canvas</a>. The sidebar highlights
        are grouped and sorted according to their{" "}
        <a href="#highlightercategories">highlighter categories</a>. You can see
        the current highlighter category of a sidebar highlight by hovering over
        it with your mouse. If you left-click a highlight (single-click on a
        trackpad), it will bring you to the location of that highlight in the
        Reader. Right-clicking a highlight (double-clicking on a trackpad)
        brings up a <a href="#contextmenu">context menu</a> with options to
        delete that highlight or change its category.
      </p>
      <h3 className={TC.h3TC} id="toolbar">
        Toolbar
      </h3>
      <p>
        The toolbar in AnnoGram includes commonly used functions. Clicking the
        "?" opens a list of short videos on how to use the application.
      </p>
      <h3 className={TC.h3TC} id="reader">
        Reader View
      </h3>
      <p>
        AnnoGram has a unique approach to document annotation. It uses{" "}
        <a href="#highlightercategories">highlighter categories</a> to
        categorize and sort <a href="#texthighlight">text highlights</a> and{" "}
        <a href="#imagehighlights">image highlights</a>. After highlighting text
        or an image, a context menu will appear with the option to add a comment
        and select a category. After making your selection, your highlight is
        sorted into its appropriate category in the Sidebar.
      </p>
      <h3 className={TC.h3TC} id="canvas">
        Canvas View
      </h3>
      <p>
        AnnoGram includes a diagramming view called the "canvas." The canvas can
        be used on its own. Double-clicking the canvas launches a{" "}
        <a href="#texteditor">text editor</a>, which you can use to create{" "}
        <a href="#node">text nodes</a>. To create a directed{" "}
        <a href="#edge">edge</a> (or arrow), drag and drop one text node onto
        another. Double-clicking a node allows you to label or delete it.
        Double-clicking an edge allows you to change its pattern (dashed or
        solid), or label or delete it.
      </p>
      <p>
        What distinguishes AnnoGram from most other diagramming or mind-mapping
        applications is its ability to use all of the above functions with
        sidebar highlights. Text and image highlights can be dragged and dropped
        onto the canvas and turned into diagrams with or without text nodes.
      </p>

      <h2 className={TC.h2TC} id="glossary">
        Glossary
      </h2>
      <h3 className={TC.h3TC} id="contextMenu">
        Context Menu
      </h3>
      <p>
        Context menus in AnnoGram contain functions for manipulating data. You
        can access them by right-clicking (mouse) or double-clicking (trackpad).
        Explore and click away! You'll find them all over the place.
      </p>
      <h3 className={TC.h3TC} id="highlightercategories">
        Higlighter Categories
      </h3>
      <p>
        Highlighter categories group and categorize image and text highlights.
        Each category is associated with a name and a color. Colors make it easy
        to find highlights of a given category in the reader at a glance.
        Category colors also appear when hovering over the sidebar and around
        nodes in the canvas.
      </p>
      <h3 className={TC.h3TC} id="opendocument">
        Open Document
      </h3>
      <p>
        {" "}
        The "Open Document" button in AnnoGram allows you to import documents
        into the application. Currently, you can import PDFs from both local
        storage and URLs. In future releases, we plan to add support for
        importing other file types and webpages.
      </p>
      <h3 className={TC.h3TC} id="editcategories">
        Edit Categories
      </h3>
      <p>
        The "Edit Categories" button allows you to modify, add, or remove
        highlighter categories. Any changes you make will be reflected in the
        reader and sidebar once they are saved
      </p>
      <h3 className={TC.h3TC} id="resethighlights">
        Reset Highlights
      </h3>
      <p>
        You can reset <em>all</em> the highlights in the reader and sidebar. Be
        careful! This change cannot be undone.{" "}
      </p>
      <h3 className={TC.h3TC} id="resetcanvas">
        Reset Canvas
      </h3>
      <p>
        You can reset <em>all</em> the nodes and edges on the canvas. Be
        careful! This action cannot be undone.{" "}
      </p>
      <h3 className={TC.h3TC} id="export">
        Export
      </h3>
      <p>
        AnnoGram currently supports exporting the Canvas to an image (.png). But
        we are working hard to support various export options in AnnoGram. Stay
        tuned.
      </p>
      <h3 className={TC.h3TC} id="switch">
        Canvas/Reader Switch
      </h3>
      <p>
        Toggling this switch changes the view from reader to canvas, or vice
        versa.
      </p>
      <h3 className={TC.h3TC} id="timer">
        Timer
      </h3>
      <p>
        There is a simple count-up timer (stopwatch) in the toolbar. Use it to
        measure how long it takes you to work through an article.{" "}
      </p>
      <h3 className={TC.h3TC} id="userinfo">
        User Info
      </h3>
      <p>
        The user info dropdown allows you to logout of the app or visit your
        profile page.
      </p>
      <h3 className={TC.h3TC} id="texteditor">
        Text Editor
      </h3>
      <p>
        The text editor can be accessed by right- or double-clicking on the
        canvas. It is a simple plain-text editor that allows you to create text
        nodes. You can also access the text editor by right- or double-clicking
        on a text highlight, which gives you the option to edit the highlight.
      </p>
      <h3 className={TC.h3TC} id="node">
        Nodes
      </h3>
      <p>
        "Nodes" refers to the boxes that appear on the canvas. There are three
        types of nodes:
      </p>
      <ul>
        <li>Text nodes, which are created using the text editor.</li>
        <li>
          Highlighter nodes, which are created by highlighting text in the
          reader.
        </li>
        <li>
          Image nodes, which are created by highlighting images/diagrams in the
          reader.
        </li>
      </ul>
      <p>
        Each type of node can be dragged and dropped onto any other to create
        edges among them.
      </p>
      <h3 className={TC.h3TC} id="edge">
        Edges
      </h3>
      <p>
        Edges are used to represent relationships among nodes. They can be
        uni-(directed) or bi-directional (undirected), and solid or dashed.
        Labels can also be added to edges.
      </p>
      <h3 className={TC.h3TC} id="texthighlights">
        Text Highlights
      </h3>
      <p>
        Text highlights are created by selecting the text of a document. To
        create an editable highlight node, simply drag a text highlight from the
        sidebar onto the canvas.
      </p>
      <h3 className={TC.h3TC} id="imagehighlights">
        Image Highlights
      </h3>
      <p>
        To create image highlights, hold down the option/alt key (⌥) and drag
        your cursor across the location you want to highlight. The image
        highlighter can be used on any part of the document, but it is most
        useful for images and diagrams, which do not work with the text
        highlighter.
      </p>
    </main>
  );
}
