import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import {
  ICanvasArrow,
  CanvasSave,
  CanvasState,
  Rectangle,
} from "../types/canvasTypes";

const initialState: CanvasState = {
  rectangles: [],
  scale: 1,
  label: "",
  tag: "",
  projectId: "",
};

export const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    addArrow: (
      draft: CanvasState,
      action: PayloadAction<
        {
          startRectIdx: number;
        } & ICanvasArrow
      >
    ) => {
      const { startRectIdx, ...arrow } = action.payload;
      draft.rectangles[startRectIdx]?.arrows.push(arrow);
    },
    addArrowTag: (
      draft: CanvasState,
      action: PayloadAction<{
        startRectIdx: number;
        endRectIdx: number;
        textInput: string;
      }>
    ) => {
      const { startRectIdx, endRectIdx, textInput } = action.payload;

      const arrow = draft.rectangles[startRectIdx]?.arrows.find(
        ({ endRectIdx: end }) => end === endRectIdx
      );
      if (!arrow) return;
      arrow.tag = textInput;
    },
    addRectangle: (draft: CanvasState, action: PayloadAction<Rectangle>) => {
      draft.rectangles.push(action.payload);
    },
    addTag: (
      draft: CanvasState,
      action: PayloadAction<{ idx: number; textInput: string }>
    ) => {
      const { idx, textInput } = action.payload;
      const rect = draft.rectangles[idx];
      if (!rect) return;
      rect.tag = textInput;
    },
    dashArrow: (
      draft: CanvasState,
      action: PayloadAction<{ startRectIdx: number; endRectIdx: number }>
    ) => {
      const { startRectIdx, endRectIdx } = action.payload;
      const arrow = draft.rectangles[startRectIdx]?.arrows.find(
        ({ endRectIdx: end }) => end === endRectIdx
      );
      if (!arrow) return;
      arrow.dash = !arrow.dash;

      const revArrow = draft.rectangles[endRectIdx]?.arrows.find(
        ({ endRectIdx: end }) => end === startRectIdx
      );
      if (!revArrow) return;
      revArrow.dash = !revArrow.dash;
    },
    deleteArrow: (
      draft: CanvasState,
      action: PayloadAction<{ startRectIdx: number; endRectIdx: number }>
    ) => {
      const { startRectIdx, endRectIdx } = action.payload;

      const arrowIdx = draft.rectangles[startRectIdx]?.arrows.findIndex(
        ({ endRectIdx: end }) => end === endRectIdx
      );
      if (arrowIdx === undefined || arrowIdx === -1) return;
      draft.rectangles[startRectIdx]?.arrows.splice(arrowIdx, 1);

      const revArrowIdx = draft.rectangles[endRectIdx]?.arrows.findIndex(
        ({ endRectIdx: end }) => end === startRectIdx
      );
      if (revArrowIdx === undefined || revArrowIdx === -1) return;
      draft.rectangles[endRectIdx]?.arrows.splice(revArrowIdx, 1);
    },
    deleteRectangle: (
      draft: CanvasState,
      action: PayloadAction<{ idx: number }>
    ) => {
      const { idx } = action.payload;
      /* Remove all arrows pointing to this rectangle first 
      otherwise idx in the splice() will refer to the wrong element */
      for (const rect of draft.rectangles) {
        rect.arrows = rect.arrows.filter(
          ({ endRectIdx }) => endRectIdx !== idx
        );
      }
      draft.rectangles.splice(idx, 1);
    },
    doubleArrow: (
      draft: CanvasState,
      action: PayloadAction<{ startRectIdx: number; endRectIdx: number }>
    ) => {
      const { startRectIdx, endRectIdx } = action.payload;
      const arrow = draft.rectangles[startRectIdx]?.arrows.find(
        ({ endRectIdx: end }) => end === endRectIdx
      );
      if (!arrow) return;
      arrow.double = true;
      // We create a reverse arrow so that we can traverse the graph if we need to
      draft.rectangles[endRectIdx]?.arrows.push({
        ...arrow,
        endRectIdx: startRectIdx,
        doNotRender: true,
      });
    },
    moveRectangle: (
      draft: CanvasState,
      action: PayloadAction<{ idx: number; x: number; y: number }>
    ) => {
      const { idx, x, y } = action.payload;
      const rect = draft.rectangles[idx];
      if (!rect) return;
      rect.x = x;
      rect.y = y;
    },
    editRectangle: (
      draft: CanvasState,
      action: PayloadAction<{
        idx: number;
        width: number;
        height: number;
        textInput: string;
      }>
    ) => {
      const { idx, width, height, textInput } = action.payload;
      const rect = draft.rectangles[idx];
      if (!rect) return;
      rect.expanded = false;
      rect.height = height;
      rect.width = width;
      rect.text = textInput;
    },

    resetCanvas: (draft: CanvasState) => {
      draft.rectangles = [];
    },
    toggleRectangleExpansion: (
      draft: CanvasState,
      action: PayloadAction<{ idx: number; width: number; height: number }>
    ) => {
      const { idx, width, height } = action.payload;
      const rect = draft.rectangles[idx];
      if (!rect) return;
      rect.expanded = !rect.expanded;
      rect.width = width;
      rect.height = height;
    },
    scaleCanvas: (
      draft: CanvasState,
      action: PayloadAction<{ scale: number }>
    ) => {
      draft.scale = action.payload.scale;
    },
    selectFetchedSave: (
      draft: CanvasState,
      {
        payload: { rectangles, label, scale, tag, projectId },
      }: PayloadAction<CanvasSave>
    ) => {
      draft.rectangles = rectangles ?? [];
      draft.label = label ?? "";
      draft.scale = scale ?? 1;
      draft.tag = tag ?? "";
      draft.projectId = projectId;
    },

    updateCanvasId: (draft: CanvasState, action: PayloadAction<string>) => {
      draft.projectId = action.payload;
    },
  },
});

export const {
  selectFetchedSave,
  scaleCanvas,
  addArrowTag,
  dashArrow,
  deleteArrow,
  doubleArrow,
  addArrow,
  addTag,
  editRectangle,
  deleteRectangle,
  toggleRectangleExpansion,
  moveRectangle,
  resetCanvas,
  addRectangle,
  updateCanvasId,
} = canvasSlice.actions;

export const selectCanvas = (state: RootState) => state.canvas;

export default canvasSlice.reducer;
