import React, { useState } from "react";
import "../style/Tip.css";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from "@mui/material";
interface TipProps {
  onConfirm: (comment: { text: string; category: string }) => void;
  onOpen: () => void;
  onUpdate?: () => void;
  categoryLabels: Array<{ label: string; background: string }>;
}

const Tip = ({ onConfirm, onOpen, categoryLabels }: TipProps) => {
  const [compact, setCompact] = useState<boolean>(true);
  const [text, setText] = useState<string>("");
  const [category, setCategory] = useState<string>("");

  const theme = useTheme();

  const handleOpen = () => {
    onOpen();
    setCompact(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onConfirm({ text, category });
  };

  return (
    <div className="Tip">
      {compact ? (
        <div className="Tip__compact" onClick={handleOpen}>
          Add highlight
        </div>
      ) : (
        <Box
          component="form"
          sx={{
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            padding: "10px",
            backgroundClip: "padding-box",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(37, 40, 43, 0.2)",
          }}
          onSubmit={handleSubmit}
        >
          <div className="Tip__content">
            <TextField
              placeholder="Your comment"
              multiline
              variant="outlined"
              autoFocus
              value={text}
              onChange={(event) => setText(event.target.value)}
              inputRef={(node) => {
                if (node) {
                  node.focus();
                }
              }}
              sx={{ width: "250px", fontSize: "14px" }}
            />

            <div className="Tip__list">
              <RadioGroup>
                {categoryLabels.map((_category) => (
                  <FormControlLabel
                    label={_category.label}
                    key={_category.label}
                    control={
                      <Radio
                        sx={{ color: _category.background }}
                        checked={category === _category.label}
                        name="category"
                        value={_category.label}
                        onChange={(event) => setCategory(event.target.value)}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
          <Button
            type="submit"
            variant="outlined"
            fullWidth
            size="small"
            color="secondary"
          >
            Save
          </Button>
        </Box>
      )}
    </div>
  );
};

export default Tip;
