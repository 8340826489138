import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { logTime, reset, setisRunning, setTime } from "../redux/stopwatchSlice";
import Dialog from "@mui/material/Dialog";
import { Box, ButtonGroup, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { setTimeLog } from "../services/supabaseAPI";
import { supabase } from "../utilities/supabaseClient";

interface Props {
  modalVisible: string;
  setModalVisible: Dispatch<SetStateAction<string>>;
}
const StopWatchTest = ({ modalVisible, setModalVisible }: Props) => {
  const stopwatchState = useAppSelector((state) => state.stopwatch);
  const highlightState = useAppSelector((state) => state.highlights);
  const userState = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const handleClose = () => setModalVisible("");

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (stopwatchState.isRunning) {
      interval = setInterval(() => {
        dispatch(setTime());
      }, 1000);
    } else if (!stopwatchState.isRunning && interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [dispatch, stopwatchState.isRunning]);

  useEffect(() => {
    if (userState.userId && highlightState.id) {
      const getTimeLog = async (id: string | undefined) => {
        let { data, error } = await supabase
          .from("documents")
          .select("time_log")
          .eq("document_id", id);
        const timeLog = data?.[0]?.time_log;
        if (!error && timeLog) {
          dispatch(logTime(timeLog));
        }
      };

      getTimeLog(highlightState.id);
    }
  }, [dispatch, userState.userId, highlightState.id]);

  const handleLogTime = async () => {
    await setTimeLog(
      highlightState.id,
      stopwatchState.time + stopwatchState.timeLog
    );
    dispatch(logTime(stopwatchState.time + stopwatchState.timeLog));
    dispatch(reset());
  };

  return (
    <Dialog
      open={modalVisible === "watch"}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth={true}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          marginLeft: "20px",
          marginTop: "40px",
        },
        "& .MuiDialog-paper": {
          background: theme.palette.background.paper,
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", right: 0, top: 1 }}
      >
        <Close />
      </IconButton>
      <DialogTitle sx={{ margin: "auto" }} fontSize={36}>
        Timer
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", fontSize: "50px" }}>
          <span>
            {("0" + Math.floor(stopwatchState.time / 3600)).slice(-2)}:
          </span>
          <span>
            {("0" + Math.floor((stopwatchState.time / 60) % 60)).slice(-2)}:
          </span>
          <span>{("0" + Math.floor(stopwatchState.time % 60)).slice(-2)}</span>
        </DialogContentText>
      </DialogContent>
      <Box
        sx={{
          background: theme.palette.background.default,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "20px" }}>
          Total time spent:&nbsp;
          {("0" + Math.floor(stopwatchState.timeLog / 3600)).slice(-2)}:
          {("0" + Math.floor((stopwatchState.timeLog / 60) % 60)).slice(-2)}:
          {("0" + Math.floor(stopwatchState.timeLog % 60)).slice(-2)}
        </Typography>
      </Box>
      <Divider />
      <DialogActions>
        <ButtonGroup variant="contained" sx={{ margin: "auto" }}>
          <Button
            sx={{ width: "6em" }}
            onClick={() => dispatch(setisRunning())}
          >
            {stopwatchState.isRunning ? "Stop" : "Start"}
          </Button>
          <Button sx={{ width: "6em" }} onClick={() => dispatch(reset())}>
            Reset
          </Button>
          <Button sx={{ width: "6em" }} onClick={handleLogTime}>
            Log
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default StopWatchTest;
