import React, { useState } from "react";
import "../style/TextAreaModal.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Modal, Stack, Typography } from "@mui/material";

interface Props {
  initialInput?: string;
  title: string;
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>, input: string) => void;
  handleCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const TextAreaModal = ({
  initialInput = "",
  title,
  handleSubmit,
  handleCancel,
}: Props) => {
  const [input, setInput] = useState<string>(initialInput);

  return (
    <Modal
      open={true}
      onClose={handleCancel}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Stack
        sx={(theme) => ({
          padding: theme.spacing(2, 2),
          minWidth: "250px",
          maxWidth: "500px",
          maxHeight: "66vh",
          flex: "1 1 auto",
          backgroundColor: theme.palette.background.paper,
        })}
        gap={2}
        borderRadius={2}
      >
        <Typography variant="h4">{title}</Typography>
        <TextField
          multiline
          value={input}
          onChange={(e) => setInput(e.target.value)}
          minRows={1}
          maxRows={8}
        />
        <Stack direction="row" justifyContent="end" gap={2}>
          <Button variant="contained" onClick={(e) => handleSubmit(e, input)}>
            Save
          </Button>
          <Button variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default TextAreaModal;
