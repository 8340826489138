import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface editor {
  editorState: any;
  editorId: string | undefined;
}

const initialState: editor = {
  editorState: null,
  editorId: "",
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setEditorState: (state, action: PayloadAction<any>) => {
      state.editorState = action.payload ?? null;
    },
    selectEditorSaves: (state, action: PayloadAction<any>) => {
      state.editorId = action.payload.editorId ?? "";
      state.editorState = action.payload.editorState ?? null;
    },
  },
});

export const { setEditorState, selectEditorSaves } = editorSlice.actions;

export default editorSlice.reducer;
