import React from "react";
import { Container, Box, Typography, Tabs, Tab, Paper } from "@mui/material";
import TermsOfService from "../landing-page/TermsOfService";
import PrivacyPolicy from "../landing-page/PrivacyPolicy";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const LegalDisclaimerScreen: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Box sx={{ mt: 2, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms of Service and Privacy Policy
        </Typography>

        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="secondary"
            onChange={handleChange}
            aria-label="legal documents tabs"
          >
            <Tab label="Terms of Service" />
            <Tab label="Privacy Policy" />
          </Tabs>
        </Paper>

        <TabPanel value={value} index={0}>
          <TermsOfService setValue={setValue} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <PrivacyPolicy />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default LegalDisclaimerScreen;
