import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { loginSchema } from "../utilities/yupScehma";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import { CustomTextField } from "../reusable/CustomTextField";
import { useAppDispatch } from "../redux/reduxHooks";
import { login } from "../redux/authSlice";

interface MyForm {
  email: string;
  password: string;
}

const SigninForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MyForm>({
    resolver: yupResolver(loginSchema),
  });
  const dispatch = useAppDispatch();

  const submitForm = (data: MyForm) => {
    //await supaAuth.login(data);
    dispatch(login(data));
  };

  return (
    <Box
      sx={{
        marginTop: "15px",
        marginX: "40px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <CustomTextField
          {...register("email")}
          name="email"
          label="Email"
          error={!!errors.email}
          helperText={errors.email && errors?.email?.message}
        />
        <CustomTextField
          {...register("password")}
          name="password"
          label="Password"
          type="password"
          error={!!errors.password}
          helperText={errors.password && errors?.password?.message}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};
export default SigninForm;
