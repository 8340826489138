import { useState } from "react";
import { IHighlight } from "@argument-studio/react-pdf-highlighter-with-categories";
import "../style/ContextMenu.css";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import {
  changeHighlightCategory,
  changeHighlightComment,
  deleteHighlight,
} from "../redux/highlightsSlice";
import {
  List,
  useTheme,
  Collapse,
  RadioGroup,
  Radio,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";

import ListItemButton from "@mui/material/ListItemButton";
import { ExpandLess, ExpandMore, SaveOutlined } from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";

interface Props {
  selectedHighlight: IHighlight;
}

const HighlightPopup = ({ selectedHighlight }: Props) => {
  const theme = useTheme();
  const state = useAppSelector((state) => state.highlights);
  const dispatch = useAppDispatch();
  const [IsTopLiOpen, setIsTopLiOpen] = useState(false);
  const [isEditCategoriesOpen, setIsEditCategoriesOpen] = useState(false);
  const [categoryValue, setCategoryValue] = useState("");
  const [commentValue, setCommentValue] = useState(
    selectedHighlight.comment.text
  );
  const [focus, setFocus] = useState(false);

  const handleTopLiClick = () => {
    setIsTopLiOpen(!IsTopLiOpen);
  };

  const handleCategoriesClick = () => {
    setIsEditCategoriesOpen(!isEditCategoriesOpen);
    setCategoryValue(selectedHighlight.comment.category);
  };

  const handleHighlightDelete = () => {
    dispatch(deleteHighlight({ id: selectedHighlight?.id }));
  };

  const handleCategoryEdit = (category: string) => {
    setCategoryValue(category);
    dispatch(changeHighlightCategory({ id: selectedHighlight.id, category }));
  };

  const handleCommentEdit = (comment: string) => {
    dispatch(
      changeHighlightComment({ id: selectedHighlight.id, comment: comment })
    );
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        borderRadius: "5px",
        opacity: 0.9,
        maxWidth: "20vw",
        minWidth: "150px",
      }}
    >
      <List sx={{ padding: 0 }}>
        <ListItem>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            type="text"
            value={commentValue}
            sx={{ marginLeft: "-10px" }}
            onFocus={() => setFocus(true)}
            multiline={focus}
            placeholder={commentValue ? commentValue : "Add a comment..."}
            onChange={(e) => setCommentValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    sx={{ color: theme.palette.text.primary }}
                    onClick={() => handleCommentEdit(commentValue)}
                  >
                    <SaveOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {IsTopLiOpen ? (
            <IconButton
              sx={{ marginRight: "-15px" }}
              onClick={handleTopLiClick}
            >
              <ExpandLess />
            </IconButton>
          ) : (
            <IconButton
              sx={{ marginRight: "-15px" }}
              onClick={handleTopLiClick}
            >
              <ExpandMore />
            </IconButton>
          )}
        </ListItem>
        <Collapse in={IsTopLiOpen} timeout="auto" unmountOnExit>
          <ListItemButton onClick={handleHighlightDelete}>
            <ListItemText
              primary="Delete Highlight"
              primaryTypographyProps={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            />
          </ListItemButton>
          <ListItem>
            <ListItemText
              primary="Edit Category"
              primaryTypographyProps={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              aria-multiline="false"
            />
            {isEditCategoriesOpen ? (
              <IconButton
                onClick={handleCategoriesClick}
                sx={{ marginRight: "-15px" }}
              >
                <ExpandLess />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleCategoriesClick}
                sx={{ marginRight: "-15px" }}
              >
                <ExpandMore />
              </IconButton>
            )}
          </ListItem>
          <Collapse in={isEditCategoriesOpen} timeout="auto" unmountOnExit>
            <RadioGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              value={categoryValue}
            >
              {state.categories.map((cat) => {
                if (cat.label === categoryValue) {
                  return (
                    <Tooltip title={cat.label} key={cat.label}>
                      <Radio
                        key={cat.label}
                        value={cat.label}
                        sx={{
                          color: cat.background,
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={() => handleCategoryEdit(cat.label)}
                      />
                    </Tooltip>
                  );
                } else {
                  return (
                    <Tooltip title={cat.label} key={cat.label}>
                      <Radio
                        key={cat.label}
                        sx={{
                          color: cat.background,
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={() => handleCategoryEdit(cat.label)}
                        value={cat.label}
                      />
                    </Tooltip>
                  );
                }
              })}
            </RadioGroup>
          </Collapse>
        </Collapse>
      </List>
    </Box>
  );
};

export default HighlightPopup;
