import { useTheme } from "@mui/material";
import Konva from "konva";
import { Arrow, Group, Label, Tag, Text } from "react-konva";
import { openArrowContextMenu } from "../redux/canvasAuxSlice";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { ICanvasArrow, RectangleGeometry } from "../types/canvasTypes";
import { addMargin, rectsHaveIntersection } from "../utilities/utils";

interface CanvasArrowProps extends ICanvasArrow {
  startRectIdx: number;
}

interface ArrowOnRectangleParams {
  angle: number;
  rectangle: RectangleGeometry;
  determineX: (y: number) => number;
  determineY: (x: number) => number;
}

const getRectangleCenter = ({ x, y, width, height }: RectangleGeometry) => ({
  x: x + width / 2,
  y: y + height / 2,
});

const calculateY = (c: number) => (tangent: number) => (x: number) =>
  tangent * x + c;

const calculateX = (c: number) => (tangent: number) => (y: number) =>
  (y - c) / tangent;

function isRightSide(angle: number, baseAngle: number) {
  return (
    (angle <= baseAngle && angle > 0) || (angle <= 0 && angle > -baseAngle)
  );
}

function isTopSide(angle: number, baseAngle: number) {
  return angle > baseAngle && angle <= Math.PI - baseAngle;
}

function isLeftSide(angle: number, baseAngle: number) {
  return (
    (angle <= Math.PI && angle > Math.PI - baseAngle) ||
    (angle <= -Math.PI + baseAngle && angle > -Math.PI)
  );
}

const calculateArrowOnRectangle = ({
  angle,
  rectangle: { x: left, y: top, width, height },
  determineX,
  determineY,
}: ArrowOnRectangleParams): [number, number] => {
  const right = left + width;
  const bottom = top + height;
  const baseAngle = Math.atan(height / width);

  if (isRightSide(angle, baseAngle)) {
    return [left, determineY(left)];
  }

  if (isTopSide(angle, baseAngle)) {
    return [determineX(top), top];
  }

  if (isLeftSide(angle, baseAngle)) {
    return [right, determineY(right)];
  }
  return [determineX(bottom), bottom];
};

/**
 * @param startRect x, y, width, height
 * @param endRect x, y, width, height
 * @returns [startX, startY, endX, endY]
 */
const calculateArrowPoints = (
  startRect: RectangleGeometry,
  endRect: RectangleGeometry
): [number, number, number, number] => {
  const { x: startCenterX, y: startCenterY } = getRectangleCenter(startRect);

  const { x: endCenterX, y: endCenterY } = getRectangleCenter(endRect);

  const dx = startCenterX - endCenterX;
  const dy = startCenterY - endCenterY;

  const tangent = dy / dx;

  const c = -tangent * startCenterX + startCenterY;

  if (tangent === Infinity) {
    return [startCenterX, startRect.y, endCenterX, endRect.y + endRect.height];
  }

  if (tangent === -Infinity) {
    return [
      startCenterX,
      startRect.y + startRect.height,
      endCenterX,
      endRect.y,
    ];
  }

  const determineY = calculateY(c)(tangent);
  const determineX = calculateX(c)(tangent);

  const angle = Math.atan2(dy, dx);

  return [
    ...calculateArrowOnRectangle({
      angle,
      rectangle: startRect,
      determineX,
      determineY,
    }),
    ...calculateArrowOnRectangle({
      angle: angle > 0 ? angle - Math.PI : angle + Math.PI,
      rectangle: endRect,
      determineX,
      determineY,
    }),
  ];
};

const CanvasArrow = ({
  startRectIdx,
  endRectIdx,
  tag,
  double,
  dash,
}: CanvasArrowProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const canvasState = useAppSelector((state) => state.canvas);
  const startRect = canvasState.rectangles[startRectIdx];
  const endRect = canvasState.rectangles[endRectIdx];

  if (!startRect || !endRect) return null;

  if (rectsHaveIntersection(startRect, endRect)) return null;

  const [startX, startY, endX, endY] = calculateArrowPoints(
    addMargin(startRect, 5),
    addMargin(endRect, 5)
  );

  const dx = startX - endX;
  const dy = startY - endX;

  const isPointerShown = Math.sqrt(dx ** 2 + dy ** 2) >= 20;

  const textX = (startX + endX) / 2;
  const textY = (startY + endY) / 2 - 5;

  const handleContextMenu = (
    e: Konva.KonvaEventObject<MouseEvent>,
    startRectIdx: number,
    endRectIdx: number
  ) => {
    e.evt.preventDefault();
    dispatch(openArrowContextMenu({ idx: [startRectIdx, endRectIdx] }));
  };

  return (
    <Group
      onContextMenu={(e) => handleContextMenu(e, startRectIdx, endRectIdx)}
    >
      <Arrow
        points={[startX, startY, endX, endY]}
        stroke={"rgb(105, 100, 90)"}
        strokeWidth={1.5}
        fill={"rgb(105, 100, 90)"}
        pointerAtEnding={isPointerShown}
        pointerAtBeginning={double && isPointerShown}
        dashEnabled={dash}
        dash={[8, 4]}
        hitStrokeWidth={30}
      />
      <Label x={textX} y={textY}>
        <Tag fill={theme.palette.background.default} opacity={1} />
        <Text text={tag} wrap="none" fill={theme.palette.text.primary} />
      </Label>
    </Group>
  );
};

export default CanvasArrow;
