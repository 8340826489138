import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useMemo } from "react";
import { useAppSelector } from "./redux/reduxHooks";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { themeSettings } from "./utilities/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArgStudioScreen from "./screens/ArgStudioScreen";
import DocumentationScreen from "./screens/DocumentationScreen";
import Landing from "./landing-page/Layout";
import { CssBaseline } from "@mui/material";
import MainScreen from "./screens/MainScreen";
import DocumentsScreen from "./screens/DocumentsScreen";
import ProfileScreen from "./screens/ProfileScreen";
import AuthContext from "./helpers/AuthContext";
import AdminPanel from "./admin-panel/Layout";
import LegalDisclaimerScreen from "./screens/LegalDisclaimerScreen";
import GoodbyeScreen from "./screens/GoodbyeScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
function App() {
  const mode = useAppSelector((state) => state.theme.mode);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthContext>
            <Routes>
              <Route element={<Landing />}>
                <Route path="/" element={<MainScreen />} />
                <Route path="documentation" element={<DocumentationScreen />} />
                <Route path="legal" element={<LegalDisclaimerScreen />} />
              </Route>
              <Route path="annogram" element={<ArgStudioScreen />} />
              <Route path="resetpassword" element={<ResetPasswordScreen />} />
              <Route path="goodbye" element={<GoodbyeScreen />} />
              <Route element={<AdminPanel />}>
                <Route path="documents" element={<DocumentsScreen />} />
                <Route path="profile" element={<ProfileScreen />} />
                <Route path="help" element={<DocumentationScreen />} />
              </Route>
            </Routes>
          </AuthContext>
        </ThemeProvider>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
