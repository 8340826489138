import { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useTheme,
} from "@mui/material";
import {
  ArrowDropDownOutlined,
  ArticleOutlined,
  DarkModeOutlined,
  LightModeOutlined,
  Menu as MenuIcon,
  NotificationsOutlined,
  PersonOutlined,
} from "@mui/icons-material";
import FlexBetween from "../reusable/FlexBetween";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { useNavigate } from "react-router-dom";
import { changeMode } from "../redux/themeSlice";
import { clearAll, logout } from "../redux/authSlice";
import Tooltip from "@mui/material/Tooltip";
import { updateAll } from "../services/supabaseAPI";

interface Props {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  isNonMobile: boolean;
}

export const AdminNavbar = ({ isSidebarOpen, setIsSidebarOpen }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const highlightState = useAppSelector((state) => state.highlights);
  const canvasState = useAppSelector((state) => state.canvas);
  const themeState = useAppSelector((state) => state.theme);
  const editorState = useAppSelector((state) => state.editor);

  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = async () => {
    if (highlightState.id && canvasState.projectId) {
      await updateAll(canvasState, highlightState, editorState);
    }
    dispatch(clearAll());
    dispatch(logout());
    navigate("/");
  };

  const handleNavigateHome = () => {
    navigate("/annogram");
  };

  return (
    <AppBar sx={{ position: "static", background: "none", boxShadow: "none" }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* Left Side */}
        <FlexBetween>
          <Tooltip title={isSidebarOpen ? "Close" : "Open"}>
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <MenuIcon />
            </IconButton>
          </Tooltip>
        </FlexBetween>
        {/* Right Side */}
        <FlexBetween gap="1.5rem">
          <Tooltip
            title={themeState.mode === "dark" ? "Toggle Light" : "Toggle Dark"}
          >
            <IconButton onClick={() => dispatch(changeMode())}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlined sx={{ color: "#99c1fe" }} />
              ) : (
                <LightModeOutlined sx={{ color: " #FFFF00" }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="No Notifications">
            <IconButton>
              <NotificationsOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reader/Canvas">
            <IconButton onClick={handleNavigateHome}>
              <ArticleOutlined />
            </IconButton>
          </Tooltip>
          <FlexBetween>
            <Button onClick={handleClick}>
              <PersonOutlined sx={{ color: theme.palette.secondary.main }} />
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary.main, fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={() => navigate("/profile")}>
                Profile Page
              </MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};
