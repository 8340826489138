import { useForm } from "react-hook-form";
import { Button, Box, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordSchema } from "../utilities/yupScehma";
import FlexBetween from "../reusable/FlexBetween";
import { CustomTextField } from "../reusable/CustomTextField";
import { changePassword } from "../services/supabaseAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AnnogramImage from "../assets/Annogram.png";

interface Password {
  newPassword: string;
  confirmPassword: string;
}

const ResetPasswordScreen = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Password>({
    resolver: yupResolver(passwordSchema),
  });

  const navigate = useNavigate();

  const submitPassword = async (data: Password) => {
    const response = await changePassword(data.newPassword);

    if (response) {
      toast.success("Password Changed Successfully");
      navigate("/documents");
    } else {
      toast.error("Something went wrong: please try again");
    }
    reset();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        marginTop: "15px",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(submitPassword)}
        sx={{
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={AnnogramImage}
            alt="annogram logo"
            style={{ width: "60%" }}
          />
        </Box>
        <Typography variant="h2" gutterBottom>
          Please enter your new password
        </Typography>
        <CustomTextField
          {...register("newPassword")}
          name="newPassword"
          label="New Password"
          type="password"
          error={!!errors.newPassword}
          helperText={errors.newPassword && errors?.newPassword?.message}
        />
        <CustomTextField
          {...register("confirmPassword")}
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          error={!!errors.confirmPassword}
          helperText={
            errors.confirmPassword && errors?.confirmPassword?.message
          }
        />
        <FlexBetween sx={{ gap: "5px" }}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Submit
          </Button>
        </FlexBetween>
      </Box>
    </Box>
  );
};

export default ResetPasswordScreen;
