import { FormEvent, useState } from "react";
import { addArrowTag, dashArrow, deleteArrow } from "../redux/canvasSlice";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import TextAreaModal from "../reusable/TextAreaModal";
import Menu from "@mui/material/Menu";
import { MenuItem } from "@mui/material";
import { closeArrowContextMenu } from "../redux/canvasAuxSlice";

const ArrowContextMenu = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { rectangles } = useAppSelector((state) => state.canvas);
  const { arrowContextMenuIdx, coords } = useAppSelector(
    (state) => state.canvasAux
  );

  if (!arrowContextMenuIdx) return null;

  const [startRectIdx, endRectIdx] = arrowContextMenuIdx;

  const closeMenu = () => {
    dispatch(closeArrowContextMenu());
    setModalOpen(false);
  };

  const handleSubmit = (e: FormEvent, textInput: string) => {
    e.preventDefault();
    dispatch(
      addArrowTag({
        startRectIdx,
        endRectIdx,
        textInput,
      })
    );
    closeMenu();
  };

  const handleArrowDash = (startRectIdx: number, endRectIdx: number) => {
    dispatch(dashArrow({ startRectIdx, endRectIdx }));
    closeMenu();
  };

  const handleDeleteArrow = (startRectIdx: number, endRectIdx: number) => {
    dispatch(deleteArrow({ startRectIdx, endRectIdx }));
    closeMenu();
  };

  return (
    <>
      <Menu
        open={true}
        anchorReference="anchorPosition"
        anchorPosition={{ left: coords.x, top: coords.y }}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => setModalOpen(true)}>Label</MenuItem>
        <MenuItem onClick={() => handleArrowDash(startRectIdx, endRectIdx)}>
          Dashes On/Off
        </MenuItem>
        <MenuItem onClick={() => handleDeleteArrow(startRectIdx, endRectIdx)}>
          Delete
        </MenuItem>
      </Menu>
      {isModalOpen ? (
        <TextAreaModal
          title="Edit Label:"
          initialInput={
            rectangles[startRectIdx]?.arrows.find(
              ({ endRectIdx: end }) => end === endRectIdx
            )?.tag
          }
          handleSubmit={handleSubmit}
          handleCancel={closeMenu}
        />
      ) : null}
    </>
  );
};

export default ArrowContextMenu;
