import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  CLEAR_EDITOR_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isAtNodeEnd,
  $isParentElementRTL,
  $wrapNodes,
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from "@lexical/rich-text";
import {
  $createCodeNode,
  $isCodeNode,
  getCodeLanguages,
  getDefaultCodeLanguage,
} from "@lexical/code";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AppBar, FormControl, Toolbar, useTheme, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  Code,
  Delete,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatUnderlined,
  InsertLink,
  RedoOutlined,
  ShapeLine,
  UndoOutlined,
} from "@mui/icons-material";
import { useAppSelector } from "../../redux/reduxHooks";
import { $createImageNode } from "../nodes/ImageNode";
import ResetWarningModal from "../../reusable/ResetWarningModal";

const LowPriority = 1;

function CodeSelector({ onChange, options, value }: any) {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select onChange={onChange} value={value}>
        {options.map((option: any) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function getSelectedNode(selection: any) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function BlockOptionsDropdownList({ editor, blockType, setBlockType }: any) {
  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
  };

  const formatSmallHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
  };

  const handleChange = (e: SelectChangeEvent) => {
    setBlockType(e.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select value={blockType} onChange={handleChange}>
        <MenuItem value="paragraph" onClick={formatParagraph}>
          Normal
        </MenuItem>
        <MenuItem value="h1" className="item" onClick={formatLargeHeading}>
          Large Heading
        </MenuItem>
        <MenuItem value="h2" className="item" onClick={formatSmallHeading}>
          Small Heading
        </MenuItem>
        <MenuItem value="ul" className="item" onClick={formatBulletList}>
          Bullet List
        </MenuItem>
        <MenuItem value="ol" className="item" onClick={formatNumberedList}>
          Numbered List
        </MenuItem>
        <MenuItem value="quote" className="item" onClick={formatQuote}>
          Quote
        </MenuItem>
        <MenuItem value="code" className="item" onClick={formatCode}>
          Code Block
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [codeLanguage, setCodeLanguage] = useState("");
  const [isRTL, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const canvasState = useAppSelector((state) => state.canvas.rectangles);
  const [modalVisible, setModalVisible] = useState("");
  const theme = useTheme();

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey: any = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsCode(selection.hasFormat("code"));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback(
    (e) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(e.target.value);
          }
        }
      });
    },
    [editor, selectedElementKey]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const convertCanvasToEditor = () => {
    canvasState.forEach((rectangle) => {
      if (rectangle.text) {
        editor.update(() => {
          const root = $getRoot();
          const textNode = $createTextNode(rectangle.text);
          const paragraphNode = $createParagraphNode();
          const quoteNode = $createQuoteNode();
          quoteNode.append(textNode);
          root.append(quoteNode);
          root.append(paragraphNode);
        });
      } else if (rectangle.image !== "") {
        editor.update(() => {
          const root = $getRoot();
          const imageNode = $createImageNode({
            src: rectangle.image!,
            altText: "",
          });
          const paragraphNode = $createParagraphNode();
          paragraphNode.append(imageNode);
          root.append(paragraphNode);
        });
      }
    });
  };

  const handleResetEditor = () => {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    editor.focus();
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: theme.palette.primary.main,
      }}
    >
      <Toolbar sx={{ marginX: "auto", background: theme.palette.primary.main }}>
        <IconButton
          disabled={!canUndo}
          onClick={() => {
            editor.dispatchCommand(UNDO_COMMAND, undefined);
          }}
          aria-label="Undo"
        >
          <UndoOutlined />
        </IconButton>
        <IconButton
          disabled={!canRedo}
          onClick={() => {
            editor.dispatchCommand(REDO_COMMAND, undefined);
          }}
          aria-label="Redo"
        >
          <RedoOutlined />
        </IconButton>
        <BlockOptionsDropdownList
          editor={editor}
          blockType={blockType}
          setBlockType={setBlockType}
        />
        {blockType === "code" ? (
          <CodeSelector
            onChange={onCodeLanguageSelect}
            options={codeLanguges}
            value={codeLanguage}
          />
        ) : (
          <>
            <IconButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
              }}
              sx={{ color: isBold ? "secondary.main" : "" }}
              aria-label="Format Bold"
            >
              <FormatBold />
            </IconButton>
            <IconButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
              }}
              sx={{ color: isItalic ? "secondary.main" : "" }}
              aria-label="Format Italics"
            >
              <FormatItalic />
            </IconButton>
            <IconButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
              }}
              sx={{ color: isUnderline ? "secondary.main" : "" }}
              aria-label="Format Underline"
            >
              <FormatUnderlined />
            </IconButton>
            <IconButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
              }}
              sx={{ color: isStrikethrough ? "secondary.main" : "" }}
              aria-label="Format Strikethrough"
            >
              <FormatStrikethrough />
            </IconButton>
            <IconButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
              }}
              sx={{ color: isCode ? "secondary.main" : "" }}
              aria-label="Insert Code"
            >
              <Code />
            </IconButton>
            <IconButton
              onClick={insertLink}
              sx={{ color: isLink ? "secondary.main" : "" }}
              aria-label="Insert Link"
            >
              <InsertLink />
            </IconButton>
            <Tooltip title="Import Canvas">
              <IconButton
                onClick={convertCanvasToEditor}
                aria-label="Import Canvas to Editor"
              >
                <ShapeLine />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear Editor">
              <IconButton
                onClick={() => {
                  setModalVisible("resetEditor");
                  editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
                  editor.focus();
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <IconButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
              }}
              aria-label="Left Align"
            >
              <FormatAlignLeft />
            </IconButton>
            <IconButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
              }}
              aria-label="Center Align"
            >
              <FormatAlignCenter />
            </IconButton>
            <IconButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
              }}
              aria-label="Right Align"
            >
              <FormatAlignRight />
            </IconButton>
            <IconButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
              }}
              aria-label="Justify Align"
            >
              <FormatAlignJustify />
            </IconButton>
          </>
        )}
      </Toolbar>
      <ResetWarningModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        warningText="This will reset the editor. Are you sure you want to proceed?"
        modalType="resetEditor"
        reset={handleResetEditor}
      />
    </AppBar>
  );
}
