import { Dispatch, SetStateAction, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { reset } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { CircularProgress, useTheme } from "@mui/material";

import SignUpForm from "./SignUpForm";
import SigninForm from "./SigninForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

interface Props {
  modalVisible: string;
  setModalVisible: Dispatch<SetStateAction<string>>;
}
const AuthenticationModal = ({ modalVisible, setModalVisible }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { isForgotPassword, isLoading, isError, isSuccess, message } =
    useAppSelector((state) => state.auth);
  const { userId } = useAppSelector((state) => state.user);

  const handleClose = useCallback(() => {
    setModalVisible("");
  }, [setModalVisible]);

  let form;
  let title;
  switch (modalVisible) {
    case "login":
      form = <SigninForm />;
      title = "Login";
      break;
    case "register":
      form = <SignUpForm />;
      title = "Register";
      break;
    case "forgot-password":
      form = <ForgotPasswordForm />;
      title = "Forgot Password";
      break;
    default:
      form = "";
  }

  useEffect(() => {
    if (isError) {
      toast.error(message, { toastId: "1" });
    }

    if (isSuccess) {
      handleClose();
      toast.success("Please confirm your email.");
    }

    if (isForgotPassword) {
      handleClose();
      toast.success("Please check your email for password reset link.");
    }

      if (userId) {
      navigate("/documents");
    }

    dispatch(reset());
  }, [
    userId,
    isSuccess,
    isForgotPassword,
    dispatch,
    navigate,
    isError,
    message,
    handleClose,
  ]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Dialog
      open={
        modalVisible === "register" ||
        modalVisible === "login" ||
        modalVisible === "forgot-password"
      }
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          background: theme.palette.background.paper,
        },
      }}
    >
      <AppBar
        sx={{
          position: "relative",
          background: theme.palette.background.default,
        }}
      >
        <Toolbar>
          <Typography
            sx={{
              textAlign: "center",
              ml: 3,
              flex: 1,
            }}
            variant="h2"
            component="div"
          >
            {title}
          </Typography>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon sx={{ float: "right" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {form}
      <Box
        sx={{
          marginBottom: "15px",
          marginX: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Link
          component="button"
          onClick={() =>
            modalVisible === "register"
              ? setModalVisible("login")
              : setModalVisible("register")
          }
          color={theme.palette.secondary.main}
        >
          {modalVisible === "register"
            ? "Already have an account? Login"
            : "Don't have an account? Register"}
        </Link>
        {modalVisible !== "forgot-password" ? (
          <Link
            component="button"
            color={theme.palette.secondary.main}
            sx={{ marginTop: "10px", marginX: "auto" }}
            onClick={() => setModalVisible("forgot-password")}
          >
            Forgot Password?
          </Link>
        ) : (
          ""
        )}
      </Box>
    </Dialog>
  );
};

export default AuthenticationModal;
