export const charLimit = 250;

export const textRectPadding = 5;

export const expandedTextRectWidth = 450;

export const trimmedTextRectWidth = 300;

export const canvasTextFontFamily = "Fira Code";

export const canvasTextFontSize = 16;

export const canvasTagFontFamily = "Fira Code";

export const canvasDarkText = "rgb(105, 100, 90)";
