import TableOfContents from "../documentation/TableOfContents";
import TC from "../style/TC.module.css";
import DocumentationItems from "../documentation/DocumentationItems";

export default function DocumentationScreen() {
  return (
    <div className={TC.container}>
      <TableOfContents />
      <DocumentationItems />
    </div>
  );
}
