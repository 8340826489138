import React, { useCallback, MouseEvent } from "react";
import { DragIndicator } from "@mui/icons-material";
import Box from "@mui/material/Box";
import "../style/cursors.css";

interface Props {
  setSidebarWidth: (width: number) => void;
  closeSidebar: () => void;
}

const minSidebarWidth: number = 100;
const maxSidebarWidth: number = 1000;
export default function ResizableHandle({
  setSidebarWidth,
  closeSidebar,
}: Props) {
  const handleMouseDown = (e: MouseEvent) => {
    document.addEventListener("pointerup", handleMouseUp, true);
    document.addEventListener("pointermove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("pointerup", handleMouseUp, true);
    document.removeEventListener("pointermove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(
    (e) => {
      const newWidth = e.clientX - document.body.offsetLeft;
      if (newWidth > minSidebarWidth && newWidth < maxSidebarWidth) {
        setSidebarWidth(newWidth);
      } else if (newWidth < minSidebarWidth) {
        closeSidebar();
        setSidebarWidth(400);
      }
    },
    [setSidebarWidth, closeSidebar]
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "3vw",
        height: "100%",
      }}
      className={"draggable"}
      onPointerDown={(e) => handleMouseDown(e)}
    >
      <DragIndicator
        sx={{
          marginX: "auto",
          marginY: "30vh",
        }}
      />
    </Box>
  );
}
