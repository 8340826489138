import PdfViewer from "../argument-reader/PdfViewer";
import Canvas from "../argument-canvas/Canvas";
import CategoryEditModal from "../argument-navbar/CategoryEditModal";
import StopWatch from "../argument-navbar/StopWatch";

import { useState, useEffect, useRef, useCallback } from "react";
import { Box, useMediaQuery } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import "../style/ArgStudio.css";

import Tutorial from "../argument-navbar/Tutorial";

import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import ArgNavbar from "../argument-navbar/ArgNavbar";
import ArgSidebar from "../argument-sidebar/ArgSidebar";
import { updateAll } from "../services/supabaseAPI";
import useInterval from "../hooks/useInterval";
import { testHighlights } from "../utilities/test-highlights";
import {
  addDemoHighlights,
  setPdfUrl,
  resetHighlights,
} from "../redux/highlightsSlice";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditorModal from "../lexical/EditorModal";
import ResetWarningModal from "../reusable/ResetWarningModal";
import { resetCanvas } from "../redux/canvasSlice";
import Konva from "konva";

const PRIMARY_PDF_URL =
  "https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/sign/pdfs/42a6c886-615c-4f3b-a6e2-97c682026047/Argument_Diagramming_Prompts.pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwZGZzLzQyYTZjODg2LTYxNWMtNGYzYi1hNmUyLTk3YzY4MjAyNjA0Ny9Bcmd1bWVudF9EaWFncmFtbWluZ19Qcm9tcHRzLnBkZiIsImlhdCI6MTcyMDcyNTYzNywiZXhwIjoxNzUyMjYxNjM3fQ.6FLuKGDoPxYJFGJBI-D0TufRPB1UqOnZaWzagetCg60&t=2024-07-11T19%3A20%3A37.396Z";
const defaultSidebarWidth = 400;

function ArgStudioScreen() {
  const canvasState = useAppSelector((state) => state.canvas);
  const userState = useAppSelector((state) => state.user);
  const highlightState = useAppSelector((state) => state.highlights);
  const themeState = useAppSelector((state) => state.theme);
  const editorState = useAppSelector((state) => state.editor);

  const [isPdfLoaded, setIsPdfLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [destinationPage, setDestinationPage] = useState<number>(1);

  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [sidebarWidth, setSidebarWidth] = useState(defaultSidebarWidth);
  const [scale, setScale] = useState<string>("auto");
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const stageRef = useRef<Konva.Stage>(null);

  const [canvasOn, setCanvasOn] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [draggedHighlightId, setDraggedHighlightId] = useState<string>("");

  useInterval(() => {
    if (canvasState.projectId && highlightState.id && editorState.editorId) {
      return updateAll(canvasState, highlightState, editorState);
    }
  });

  useEffect(() => {
    if (!userState.userId) {
      dispatch(setPdfUrl(PRIMARY_PDF_URL));
      //  dispatch(addDemoHighlights(testHighlights[PRIMARY_PDF_URL]));
    } else if (!highlightState.id && !canvasState.projectId) {
      setIsPdfLoaded(false);
    }
  }, [dispatch, userState.userId, highlightState.id, canvasState.projectId]);

  useEffect(() => {
    const pdfColor = document.body.style;

    if (themeState.mode === "light") {
      pdfColor.setProperty("--background-color", "#f5f5f5");
    } else {
      pdfColor.setProperty("--background-color", "#989898FF");
    }
  }, [themeState]);

  const handlePageIncrease = () => {
    if (currentPage < pageCount) {
      setDestinationPage(pageCount);
    }
  };

  const handlePageDecrease = () => {
    if (currentPage > 1) {
      setDestinationPage(1);
    }
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= pageCount) {
      setDestinationPage(page);
    }
  };

  const handleHighlightDragStart = (id: string) => () =>
    setDraggedHighlightId(id);

  const toggleCanvas = () => setCanvasOn((prev) => !prev);
  const handleResetCanvas = () => {
    dispatch(resetCanvas());
  };

  const handleResetHighlights = () => {
    dispatch(resetHighlights());
  };

  const handleExportClick = useCallback(() => {
    if (!stageRef.current) {
      return;
    }

    const dataUrl = stageRef.current.toDataURL({ pixelRatio: 3 });

    let link = document.createElement("a");
    link.download = "canvasImage.png";
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return (
    <Box
      display={isNonMobile ? "flex" : "block"}
      width="100%"
      height="100%"
      onContextMenu={(e) => e.preventDefault()}
    >
      <ArgSidebar
        canvasOn={canvasOn}
        currentPage={currentPage}
        handlePageIncrease={handlePageIncrease}
        handlePageDecrease={handlePageDecrease}
        handlePageChange={handlePageChange}
        pageCount={pageCount}
        isNonMobile={isNonMobile}
        drawerWidth={`${sidebarWidth}px`}
        setSidebarWidth={setSidebarWidth}
        isSidebarOpen={isSidebarOpen}
        closeSidebar={() => setIsSidebarOpen(false)}
        handleHighlightDragStart={handleHighlightDragStart}
      />
      <ArgNavbar
        canvasOn={canvasOn}
        toggleCanvas={toggleCanvas}
        setScale={setScale}
        scale={scale}
        isNonMobile={isNonMobile}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        isEditorOpen={isEditorOpen}
        setIsEditorOpen={setIsEditorOpen}
        onExportClick={handleExportClick}
        modals={(modalVisible, setModalVisible) => [
          <CategoryEditModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            key="categoryEdit"
          />,
          <ResetWarningModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            warningText="This will reset all of your highlights. Are you sure you want to
            proceed?"
            key="resetHighlights"
            modalType="resetHighlights"
            reset={handleResetHighlights}
          />,
          <ResetWarningModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            warningText="This will reset the canvas. Are you sure you want to proceed?"
            key="canvasReset"
            modalType="resetCanvas"
            reset={handleResetCanvas}
          />,
          <StopWatch
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            key="watch"
          />,
        ]}
        tutorial={(showTutorialState, setShowTutorialState) => (
          <Tutorial
            showTutorialState={showTutorialState}
            setShowTutorialState={setShowTutorialState}
          />
        )}
      />
      {isPdfLoaded ? (
        <PdfViewer
          scale={scale}
          isSidebarOpen={isSidebarOpen}
          sidebarWidth={sidebarWidth}
          destinationPage={destinationPage}
          getPageCount={(pageCount) => setPageCount(pageCount)}
          getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
          canvasOn={canvasOn}
        />
      ) : (
        <Box
          width={isNonMobile ? "100%" : "550px"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40vh",
          }}
        >
          <Tooltip title="Load a document to get started">
            <IconButton
              onClick={() => navigate("/documents")}
              sx={{
                visibility: `${canvasOn ? "hidden" : ""}`,
              }}
            >
              <UploadFileIcon sx={{ fontSize: "250px" }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Canvas
        clearDraggedHighlightId={() => setDraggedHighlightId("")}
        draggedHighlightId={draggedHighlightId}
        canvasOn={canvasOn}
        stageRef={stageRef}
      />
      <EditorModal isEditorOpen={isEditorOpen} />,
    </Box>
  );
}

export default ArgStudioScreen;
