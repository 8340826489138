import { useState } from "react";
import useHeadingsData from "../hooks/useHeadingsData";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import Headings from "./Headings";
import TC from "../style/TC.module.css";

const TableOfContents = () => {
  const [activeId, setActiveId] = useState("");
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);
  return (
    <nav className={TC.navTC} aria-label="Table of contents">
      <Headings headings={nestedHeadings} activeId={activeId} />
    </nav>
  );
};

export default TableOfContents;
