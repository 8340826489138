import { ReactNode, useEffect } from "react";
import { supabase } from "../utilities/supabaseClient";
import { clearAll, setError, setLoading } from "../redux/authSlice";
import { updateUserId } from "../redux/userSlice";
import { useAppDispatch } from "../redux/reduxHooks";
import { useNavigate } from "react-router-dom";

interface Props {
  children: ReactNode;
}
const AuthContext = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;

    async function getSession() {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();

      // only update the react state if the component is still mounted
      if (mounted) {
        if (error) {
          dispatch(setError(error));
          dispatch(setLoading(false));
          return;
        }
        if (session) {
          dispatch(updateUserId(session.user.id));
          dispatch(setLoading(false));
        }
      }
    }

    getSession();

    return () => {
      mounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session && (event === "SIGNED_IN" || event === "TOKEN_REFRESHED")) {
        dispatch(updateUserId(session.user.id));
        //navigate("/documents");
      }

      if (event === "SIGNED_OUT") {
        dispatch(updateUserId(""));
        dispatch(clearAll());
        //navigate("/");
      }

      if (event === "PASSWORD_RECOVERY") {
        navigate("/resetpassword");
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch, navigate]);

  return <>{children}</>;
};

AuthContext.propTypes = {};

export default AuthContext;
