import * as yup from "yup";

export const registrationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email("Please enter a valid email format.")
    .required("An email address is required."),
  password: yup
    .string()
    .min(8, "Password must contain 8 characters")
    .required("A password is required."),
  confirmPassword: yup
    .string()
    .required("You need to confirm your password")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email address is incorrect. Please try again.")
    .required("An email address is required."),
  password: yup
    .string()
    .min(8, "Password is incorrect. Please try again.")
    .required("A password is required."),
});

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email format.")
    .required("An email address is required."),
});

export const nameSchema = yup.object().shape({
  firstName: yup.string().optional(),
  lastName: yup.string().optional(),
});

export const passwordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "Password must contain 8 characters")
    .required("A password is required."),
  confirmPassword: yup
    .string()
    .required("You need to confirm your password")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});
