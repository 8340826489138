import React, { useState } from "react";
import "../style/Tutorial.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import StyledButton from "../reusable/ImageButton";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Close from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";

interface Props {
  sectionEventKey: string;
  sectionTitle: string;
  imgTitle: string;
  imgSrc: string;
  imgAlt: string;
  imgCaption: string;
  gif: string;
  gifAlt: string;
}

const TutorialItem: React.FC<Props> = ({
  sectionTitle,
  imgSrc,
  imgCaption,
  gif,
}) => {
  const [showTutorialModalState, setTutorialModalState] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{sectionTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StyledButton
            url={imgSrc}
            title={<PlayCircleOutlineIcon sx={{ fontSize: "60px" }} />}
            width="100vw"
            height="35vh"
            onClick={() => setTutorialModalState(true)}
          />
          <Typography>{imgCaption}</Typography>
        </AccordionDetails>
      </Accordion>

      <Dialog
        fullScreen
        open={showTutorialModalState}
        onClose={() => setTutorialModalState(false)}
        sx={{
          "& .MuiDialog-paper": {
            background: theme.palette.background.paper,
          },
        }}
      >
        <DialogTitle onClick={() => setTutorialModalState(false)}>
          <IconButton>
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <div className="tutorial-container">
          <video className="tutorial-video" controls={true} muted={true}>
            <source src={gif} type="video/mp4" />
          </video>
        </div>
      </Dialog>
    </>
  );
};

export default TutorialItem;
