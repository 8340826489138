import {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { Data, EnhancedTableHead, Order } from "./EnhancedTableHead";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { supabase } from "../utilities/supabaseClient";
import {
  GetPersonalUrl,
  loadCanvasState,
  loadHighlightsState,
  loadEditorState,
  deleteStoredFiles,
  deleteDocuments,
} from "../services/supabaseAPI";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AttachFileOutlined, FileOpenOutlined } from "@mui/icons-material";
import { selectFetchedHighlights } from "../redux/highlightsSlice";
import { selectFetchedSave } from "../redux/canvasSlice";
import { useNavigate } from "react-router-dom";
import FlexBetween from "../reusable/FlexBetween";
import Typography from "@mui/material/Typography";
import { getComparator, parseDate, stableSort } from "./table-functions";
import Tooltip from "@mui/material/Tooltip";
import { selectEditorSaves } from "../redux/editorSlice";

interface Props {
  modalVisible: string;
  setModalVisible: Dispatch<SetStateAction<string>>;
}

export default function DocumentsTable({ setModalVisible }: Props) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("owner");
  const [selected, setSelected] = useState<{ id: string; path: string }[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [documents, setDocuments] = useState<any>([]);

  const userState = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const tableData = search
    ? documents.filter((item: any) =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )
    : documents;

  useEffect(() => {
    const normalizeDocuments = (data: any) => {
      return data.map((datum: any) => {
        return {
          title: datum.document_name,
          owner: datum.owner_id === userState.userId ? "you" : "",
          lastModified:
            parseDate(datum.updated_at) ?? parseDate(datum.created_at),
          id: datum.document_id,
          url: datum.document_url,
          projectId: datum.project_id ?? "",
          editorId: datum.editor_id ?? "",
          path: datum.document_path ?? "",
        };
      });
    };
    const getAllDocuments = async () => {
      let { data, error } = await supabase
        .from("documents")
        .select("*")
        .eq("owner_id", userState.userId);
      if (!error) {
        const formattedData = normalizeDocuments(data);
        setDocuments(formattedData);
      }
    };

    if (userState.userId) {
      getAllDocuments().catch(console.error);
    }
  }, [userState]);

  useEffect(() => {
    const documentsListener = supabase
      .channel("document_channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "documents" },
        (payload: any) => {
          const newDocument = {
            title: payload.new.document_name,
            owner: payload.new.owner_id === userState.userId ? "you" : "",
            lastModified:
              parseDate(payload.new.updated_at) ??
              parseDate(payload.new.created_at),
            id: payload.new.document_id,
            url: payload.new.document_url,
            projectId: payload.new.project_id ?? "",
            editorId: payload.new.editor_id ?? "",
            path: payload.new.document_path ?? "",
          };
          if (newDocument.id) {
            setDocuments((documents: any) => [...documents, newDocument]);
          }
        }
      )
      .subscribe();

    const removeListener = async () => {
      await supabase.removeChannel(documentsListener);
    };

    return () => {
      removeListener().catch(console.error);
    };
  }, [userState.userId]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = documents?.map((n: any) => {
        return { id: n.id, path: n.path };
      });
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, id: any, path: string) => {
    const selectedIndex = selected.map((e) => e.id).indexOf(id);
    let newSelected: { id: string; path: string }[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { id, path });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: any) =>
    selected.map((e) => e.id).indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - documents.length) : 0;
  const handleDocumentOpen = async (selectedDoc: Data) => {
    const canvasRes = await loadCanvasState(selectedDoc.projectId);
    const highlightsRes = await loadHighlightsState(selectedDoc.id);
    const editorRes = await loadEditorState(selectedDoc.editorId);

    const firstCanvasRes = canvasRes?.[0];
    const firstHighlightsRes = highlightsRes?.[0];
    const firstEditorRes = editorRes?.[0];
    if (!firstCanvasRes) return;
    if (!firstHighlightsRes) return;
    if (!firstEditorRes) return;

    if (!selectedDoc.url) {
      const urlRes = await GetPersonalUrl(selectedDoc.path, selectedDoc.id);
      if (urlRes) {
        if (canvasRes && highlightsRes && editorRes) {
          dispatch(
            selectFetchedSave({
              projectId: firstCanvasRes.project_id,
            })
          );
          dispatch(
            selectFetchedHighlights({
              id: firstHighlightsRes.document_id,
              url: urlRes,
            })
          );
          dispatch(selectEditorSaves({ editorId: firstEditorRes.editor_id }));
        }
      }
      setModalVisible("");
      navigate("/annogram");
      return;
    }

    dispatch(
      selectFetchedSave({
        ...(typeof firstCanvasRes.canvas === "string"
          ? JSON.parse(firstCanvasRes.canvas)
          : undefined),
        project_id: firstCanvasRes.project_id,
      })
    );

    dispatch(
      selectFetchedHighlights({
        highlighter:
          typeof firstHighlightsRes.highlights === "string"
            ? JSON.parse(firstHighlightsRes.highlights)
            : undefined,
        categories:
          typeof firstHighlightsRes.categories === "string"
            ? JSON.parse(firstHighlightsRes.categories)
            : undefined,
        id: firstHighlightsRes.document_id,
        url: selectedDoc.url,
      })
    );

    dispatch(
      selectEditorSaves({
        editorState:
          typeof firstEditorRes.editor_state === "string"
            ? JSON.parse(firstEditorRes.editor_state)
            : firstEditorRes.editor_state,
        editorId: firstEditorRes.editor_id,
      })
    );

    setModalVisible("");
    navigate("/annogram");
  };
  const handleDeleteFile = async (id: string, path: string) => {
    const filteredDocuments = documents.filter((item: any) => item.id !== id);
    await deleteDocuments([id]);
    if (path) {
      await deleteStoredFiles([path]);
    }
    setDocuments(filteredDocuments);
  };

  const handleDeleteFiles = async () => {
    const toRemovePath = selected
      .map((item) => item.path)
      .filter((item) => item);
    const toRemoveIds = selected.map((item) => item.id);
    const difference = documents.filter(
      (item: any) => !toRemoveIds.includes(item.id)
    );

    if (toRemovePath.length > 0) {
      await deleteStoredFiles(toRemovePath);
    }

    const error = await deleteDocuments(toRemoveIds);
    if (!error) {
      setDocuments(difference);
      setSelected([]);
    }
  };

  return (
    <Box sx={{ width: "100%", marginY: 4 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleSearch={handleSearch}
          handleDelete={handleDeleteFiles}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 390 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
            />
            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) =>
                            handleClick(event, row.id, row.path)
                          }
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={row.id}
                        scope="row"
                        padding="none"
                      >
                        <FlexBetween>
                          {row.title}
                          <Box>
                            <Tooltip title="Open File">
                              <IconButton
                                sx={{ marginLeft: "5px" }}
                                onClick={() => handleDocumentOpen(row)}
                              >
                                <FileOpenOutlined />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete File">
                              <IconButton
                                onClick={() =>
                                  handleDeleteFile(row.id, row.path)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </FlexBetween>
                      </TableCell>
                      <TableCell align="right">{row.owner}</TableCell>
                      <TableCell align="right">{row.lastModified}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => setModalVisible("choosePdf")}
            sx={{ marginLeft: "8px" }}
          >
            <Typography>Upload New</Typography>
            <AttachFileOutlined />
          </IconButton>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ marginLeft: "auto" }}
          />
        </Box>
      </Paper>
    </Box>
  );
}
