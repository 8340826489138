import React, { Dispatch, SetStateAction, useState } from "react";
import { useImmer } from "use-immer";
import FormControl from "@mui/material/FormControl";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";

import { Category } from "../types/types";
import { overwriteCategories } from "../redux/highlightsSlice";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { InputBase, Paper, Stack, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

interface Props {
  modalVisible: string;
  setModalVisible: Dispatch<SetStateAction<string>>;
}

const CategoryEditModal = ({ modalVisible, setModalVisible }: Props) => {
  const theme = useTheme();
  const state = useAppSelector((state) => state.highlights);
  const dispatch = useAppDispatch();

  const [newLabel, setNewLabel] = useState("");

  const [tempCategories, setTempCategories] = useImmer<Category[]>(
    state.categories
  );

  const deleteCategoryLabel = (event: React.MouseEvent) => {
    event.preventDefault();
    const id = event.currentTarget.id;
    setTempCategories((draft) => {
      const index = draft.findIndex((category) => category.label === id);
      if (index !== -1) draft.splice(index, 1);
    });
  };

  const handleAddLabel = () => {
    if (tempCategories.length < 5 && newLabel !== "") {
      setTempCategories((draft) => {
        draft.push({
          label: newLabel,
          background: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        });
      });
    }
    setNewLabel("");
  };

  const handleCancel = () => {
    setTempCategories((draft) => {
      draft.splice(0);
      draft.push(...state.categories);
    });
    setModalVisible("");
  };

  const handleSave = () => {
    dispatch(overwriteCategories({ categories: tempCategories }));
    setModalVisible("");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLabel(event.currentTarget.value);
  };

  const handleUpdateColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.currentTarget.value;
    const id = event.currentTarget.id;
    setTempCategories((draft) => {
      const index = draft.findIndex((category) => category.label === id);
      const category = draft[index];
      if (!category) return;
      category.background = color;
    });
  };

  const listOfCategories = tempCategories.map((category) => (
    <ListItem key={category.label}>
      <FormControl>
        <Stack direction="row" spacing={5}>
          <input
            type="color"
            id={category.label}
            defaultValue={category.background}
            title="Choose your color"
            onChange={handleUpdateColor}
          />
          <Typography width={200} noWrap={true}>
            {category.label}
          </Typography>
          <Button
            variant="contained"
            id={category.label}
            onClick={deleteCategoryLabel}
            sx={{ position: "absolute", left: 250 }}
          >
            Delete
          </Button>
        </Stack>
      </FormControl>
    </ListItem>
  ));

  return (
    <Dialog
      open={modalVisible === "editCategories"}
      onClose={() => handleCancel}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          marginLeft: "20px",
          marginTop: "40px",
        },
        "	.MuiDialog-paper": {
          width: "440px",
        },
        "& .MuiDialog-paper": {
          background: theme.palette.background.paper,
        },
      }}
    >
      <Box display="flex" alignItems="center">
        <IconButton
          onClick={handleCancel}
          sx={{ position: "absolute", right: 0, top: 1 }}
        >
          <Close />
        </IconButton>
        <DialogTitle sx={{ margin: "auto" }} fontSize={36}>
          Edit Categories
        </DialogTitle>
      </Box>
      <Divider />
      <DialogContent>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 390,
          }}
        >
          <IconButton sx={{ p: "10px" }} onClick={handleAddLabel}>
            <Add />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Add New Category"
            onChange={handleInputChange}
            value={newLabel}
          />
        </Paper>
        <List
          sx={{
            marginY: "10px",
            width: "100%",
            maxWidth: 380,
          }}
        >
          {listOfCategories}
        </List>
      </DialogContent>
      <Divider />
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "center", marginY: "10px" }}
      >
        <Button variant="contained" type="submit" onClick={handleSave}>
          Submit
        </Button>
        <Button variant="contained" onClick={handleCancel}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default CategoryEditModal;
