import { Image } from "react-konva";
import useImage from "use-image";

interface Props {
  dataurl: string;
}

const CanvasImage = ({ dataurl }: Props) => {
  const [image] = useImage(dataurl);

  return <Image image={image} cornerRadius={5} />;
};

export default CanvasImage;
