import Konva from "konva";
import { IHighlight } from "@argument-studio/react-pdf-highlighter-with-categories";
import { Category } from "../types/types";
import {
  canvasTextFontFamily,
  canvasTextFontSize,
  charLimit,
  expandedTextRectWidth,
  textRectPadding,
  trimmedTextRectWidth,
} from "./consts";
import { Rectangle, RectangleGeometry } from "../types/canvasTypes";

export const calculateTrimmedTextSize = (text: string) => {
  const textComponent = new Konva.Text({
    text: text.slice(0, charLimit),
    padding: textRectPadding,
    fontFamily: canvasTextFontFamily,
    fontSize: canvasTextFontSize,
  });

  if (textComponent.size().width > trimmedTextRectWidth) {
    textComponent.width(trimmedTextRectWidth);
  }

  return textComponent.size();
};

export const calculateExpandedTextSize = (text: string) => {
  const textComponent = new Konva.Text({
    text,
    padding: textRectPadding,
    fontFamily: canvasTextFontFamily,
    fontSize: canvasTextFontSize,
  });

  if (textComponent.size().width > expandedTextRectWidth) {
    textComponent.width(expandedTextRectWidth);
  }

  return textComponent.size();
};

export const rectsHaveIntersection = (A: Rectangle, B: Rectangle) => {
  return (
    A.x + A.width > B.x &&
    B.x + B.width > A.x &&
    A.y + A.height > B.y &&
    B.y + B.height > A.y
  );
};

export const sortHighlightsByCategoryAndLocation = function (
  array: Array<IHighlight>,
  categoryLabels: Category[]
) {
  const copy = array.slice();
  copy.sort((a, b) => {
    // This function assigns a ranking for the purposes of sorting.

    const categoryOrdering = function (highlight: IHighlight) {
      if (
        categoryLabels[0] &&
        highlight.comment.category === categoryLabels[0].label
      ) {
        return 0;
      } else if (
        categoryLabels[1] &&
        highlight.comment.category === categoryLabels[1].label
      ) {
        return 1;
      } else if (
        categoryLabels[2] &&
        highlight.comment.category === categoryLabels[2].label
      ) {
        return 2;
      } else if (
        categoryLabels[3] &&
        highlight.comment.category === categoryLabels[3].label
      ) {
        return 3;
      } else {
        return 4;
      }
    };

    /*   
    A sorting function works like this: 
      On input (a,b) 
        if comparison function returns negative, a is moved before b in the array [...a, ...b...].
        if comparison function returns positive, a is moved after b in the array [...b, ...a...].
        if comparison functions returns 0, the array is unchanged. 
    See below the comparison functions for input(a,b). 
      If a's ranking value is lower than b's, then return negative value (= a is before b in the sorted array)
      If a's ranking value is higher than b's, then return positive (= a is after b in the array)
      The same is done for page number, and finally, for position on page. 
      */
    if (categoryOrdering(a) < categoryOrdering(b)) return -1;
    if (categoryOrdering(a) > categoryOrdering(b)) return 1;
    if (a.position.pageNumber < b.position.pageNumber) return -1;
    if (a.position.pageNumber > b.position.pageNumber) return 1;
    if (a.position.boundingRect.y1 < b.position.boundingRect.y1) return -1;
    if (a.position.boundingRect.y1 > b.position.boundingRect.y1) return 1;
    if (a.position.boundingRect.x1 < b.position.boundingRect.x1) return -1;
    if (a.position.boundingRect.x1 > b.position.boundingRect.x1) return 1;
    else return 0;
  });
  return copy;
};

// We need to add 5 pixels around the rect so that arrows don't touch boxes.
export const addMargin = (
  { x, y, width, height }: RectangleGeometry,
  margin: number
): RectangleGeometry => {
  return {
    x: x - margin,
    y: y - margin,
    width: width + 2 * margin,
    height: height + 2 * margin,
  };
};
