import TC from "../style/TC.module.css";

interface Props {
  headings: Array<{
    id: string;
    title: string;
    items: Array<{ id: string; title: string }>;
  }>;
  activeId: string | undefined;
}

const Headings = ({ headings, activeId }: Props) => (
  <ul>
    {headings.map((heading) => (
      <li
        key={heading.id}
        className={heading.id === activeId ? TC.focused : TC.liTC}
      >
        <a className={TC.aTC} href={`#${heading.id}`}>
          {heading.title}
        </a>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <li
                key={child.id}
                className={child.id === activeId ? TC.focused : TC.liTC}
              >
                <a className={TC.aTC} href={`#${child.id}`}>
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);
export default Headings;
