import Toolbar from "@mui/material/Toolbar";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FlexBetween from "../reusable/FlexBetween";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ChangeEvent } from "react";
import { Grid } from "@mui/material";

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleSearch: (event: ChangeEvent<HTMLInputElement>) => void;

  handleDelete: any;
}

export function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, handleSearch, handleDelete } = props;
  //  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.secondary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Grid container sx={{ alignItems: "center" }}>
          <Typography variant="h6">{numSelected} selected</Typography>
          <Tooltip title="Delete">
            <IconButton onClick={handleDelete} sx={{ marginLeft: "10px" }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Documents
        </Typography>
      )}
      <FlexBetween borderRadius="5px" gap="3rem">
        <TextField
          label="Search"
          variant="outlined"
          id="search"
          type="search"
          sx={{ width: "30ch" }}
          onChange={handleSearch}
        />
      </FlexBetween>
    </Toolbar>
  );
}
