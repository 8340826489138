import Draggable from "react-draggable";
import EditorComponent from "./components/EditorComponent";
import "../style/TextEditor.css";
import Box from "@mui/material/Box";

interface Props {
  isEditorOpen: boolean;
}
export default function EditorModal({ isEditorOpen }: Props) {
  return (
    <div
      style={{
        visibility: isEditorOpen ? undefined : "hidden",
      }}
    >
      <Draggable handle="#handle" cancel="#stop-drag">
        <Box
          sx={{
            position: "absolute",
            left: "35vw",
            top: "20vh",
            display: "block",
            zIndex: "999",
          }}
        >
          <EditorComponent />
        </Box>
      </Draggable>
    </div>
  );
}
