import { addTag, deleteRectangle, editRectangle } from "../redux/canvasSlice";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import ContextMenu from "../reusable/ContextMenu";
import TextAreaModal from "../reusable/TextAreaModal";
import { useState } from "react";
import { calculateTrimmedTextSize } from "../utilities/utils";
import { closeRectContextMenu } from "../redux/canvasAuxSlice";

const RectangleContextMenu = () => {
  const [modalType, setModalType] = useState<"label" | "edit" | "">("");
  const dispatch = useAppDispatch();
  const { rectangles } = useAppSelector((state) => state.canvas);
  const { rectContextMenuIdx: idx, coords } = useAppSelector(
    (state) => state.canvasAux
  );

  if (idx === null) return null;

  const rectangle = rectangles[idx];

  const closeMenu = () => {
    dispatch(closeRectContextMenu());
  };

  const handleDelete = () => {
    dispatch(deleteRectangle({ idx }));
    closeMenu();
  };

  const handleEditSubmit = (
    e: React.MouseEvent<HTMLButtonElement>,
    textInput: string
  ) => {
    e.preventDefault();
    dispatch(
      editRectangle({
        idx,
        textInput,
        ...calculateTrimmedTextSize(textInput),
      })
    );
    closeMenu();
    setModalType("");
  };

  const handleLabelSubmit = (
    e: React.MouseEvent<HTMLButtonElement>,
    textInput: string
  ) => {
    e.preventDefault();
    dispatch(addTag({ idx, textInput }));
    closeMenu();
    setModalType("");
  };

  const handleCancel = () => {
    closeMenu();
    setModalType("");
  };

  const sharedMenuButtons = [
    { text: "Label", onClick: () => setModalType("label") },
    { text: "Delete", onClick: handleDelete },
  ];

  const modals = {
    edit: {
      handleSubmit: handleEditSubmit,
      title: "Edit Text Content:",
      initialInput: rectangle?.text ?? "",
    },
    label: {
      handleSubmit: handleLabelSubmit,
      title: "Edit Label:",
      initialInput: rectangle?.tag ?? "",
    },
  };
  //TODO: Replace menu button prop with children
  return (
    <>
      <ContextMenu
        open={true}
        menuCoords={coords}
        menuButtons={
          rectangle?.text
            ? [
                ...sharedMenuButtons,
                { text: "Edit", onClick: () => setModalType("edit") },
              ]
            : sharedMenuButtons
        }
        onClose={closeMenu}
      />
      {modalType ? (
        <TextAreaModal handleCancel={handleCancel} {...modals[modalType]} />
      ) : null}
    </>
  );
};

export default RectangleContextMenu;
