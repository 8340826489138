import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import { ReactNode, RefObject, useEffect, useState } from "react";
import { Stage } from "react-konva";
import { setCoords } from "../redux/canvasAuxSlice";
import { addRectangle } from "../redux/canvasSlice";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { calculateTrimmedTextSize } from "../utilities/utils";
import TextAreaModal from "../reusable/TextAreaModal";

interface Props {
  stageRef: RefObject<Konva.Stage> | null;
  children: ReactNode;
}

const CanvasStage = ({ stageRef, children }: Props) => {
  const state = useAppSelector((state) => state.canvas);
  const dispatch = useAppDispatch();
  const canvasAuxState = useAppSelector((state) => state.canvasAux);
  const [stageCoordinates, setStageCoordinates] = useState<Vector2d>({
    x: 0,
    y: 0,
  });

  const [isOpenTextModal, setOpenTextModal] = useState<boolean>(false);

  /* window.innerHeight and innerWidth 
  because they fit the canvas into the dimensions of the browser window.
  Width is scaled by 75% because the argument-navbar takes 25%.
 */
  const initialSceneHeight = window.innerHeight;
  const initialSceneWidth = window.innerWidth * 0.75;

  /* The point of this useEffect hook is to enable the resizing of the stage and the objects in it
   based on changes in window size 
  We might not need this functionality, because the stage is draggable.
  I'm also thinking of implementing a mouse scroll zoom which further reduces the need for that. */

  useEffect(() => {
    function fitStageIntoParentContainer() {
      if (stageRef && stageRef.current) {
        const container: HTMLElement | null = document.querySelector(
          ".argument-canvas-container"
        );
        let containerWidth = initialSceneWidth;
        let containerHeight = initialSceneHeight;

        if (container) {
          containerWidth = container.offsetWidth;
          containerHeight = container.offsetHeight;
        }
        let xScale = containerWidth / initialSceneWidth;
        let yScale = containerHeight / initialSceneHeight;

        stageRef.current.width(initialSceneWidth * xScale);
        stageRef.current.height(initialSceneHeight * yScale);
      }
    }
    fitStageIntoParentContainer();
    window.addEventListener("resize", fitStageIntoParentContainer);
    return () =>
      window.removeEventListener("resize", fitStageIntoParentContainer);
  }, [initialSceneHeight, initialSceneWidth, stageRef]);

  const handleDoubleClick = (event: Konva.KonvaEventObject<MouseEvent>) => {
    const targetType = event.target.getType();
    const stage = stageRef?.current;

    if (targetType !== "Stage" || !stage) return;

    const position = stage.getPointerPosition();

    if (!position) return;

    const { x, y } = position;

    dispatch(setCoords({ x, y }));
    setOpenTextModal(true);
  };

  const handleSubmitCustomTextRect = (
    e: React.MouseEvent<HTMLButtonElement>,
    input: string
  ) => {
    e.preventDefault();

    dispatch(
      addRectangle({
        ...canvasAuxState.coords,
        ...calculateTrimmedTextSize(input),
        category: "user_created",
        text: input,
        expanded: false,
        tag: "",
        arrows: [],
      })
    );
    setOpenTextModal(false);
  };

  const handleDrag = (event: Konva.KonvaEventObject<DragEvent>) => {
    if (event.target.getType() !== "Stage") return;

    setStageCoordinates({ x: event.target.x(), y: event.target.y() });
  };

  const handleContextMenu = (event: Konva.KonvaEventObject<MouseEvent>) => {
    event.evt.preventDefault();
    const stage = stageRef?.current;

    if (!stage) return;

    const { x, y } = stage.getPointerPosition() || {};
    const { left, top } = stage.container().getBoundingClientRect() || {};

    if (!left || !top || !x || !y) return;

    const coords = {
      x: left + x + 4,
      y: top + y + 4,
    };
    dispatch(setCoords(coords));
  };

  return (
    <>
      <Stage
        x={stageCoordinates.x}
        y={stageCoordinates.y}
        height={initialSceneHeight}
        width={initialSceneWidth}
        ref={stageRef}
        scale={{ x: state.scale, y: state.scale }}
        //onWheel={handleWheel}
        draggable
        onDragStart={handleDrag}
        onDragMove={handleDrag}
        onDragEnd={handleDrag}
        onDblClick={handleDoubleClick}
        onContextMenu={handleContextMenu}
      >
        {children}
      </Stage>
      {isOpenTextModal ? (
        <TextAreaModal
          title={"Create a Text Rectangle:"}
          handleCancel={() => setOpenTextModal(false)}
          handleSubmit={handleSubmitCustomTextRect}
        />
      ) : null}
    </>
  );
};

export default CanvasStage;
