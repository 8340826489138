import { createSlice } from "@reduxjs/toolkit";

interface stopwatchState {
  time: number;
  isRunning: boolean;
  timeLog: number;
}

const initialState: stopwatchState = {
  time: 0,
  isRunning: false,
  timeLog: 0,
};

export const stopwatchSlice = createSlice({
  name: "stopwatch",
  initialState,
  reducers: {
    setTime: (state) => {
      state.time = state.time + 1;
    },
    setisRunning: (state) => {
      state.isRunning = !state.isRunning;
    },
    reset: (state) => {
      state.time = 0;
      state.isRunning = false;
    },
    logTime: (state, action) => {
      state.timeLog = action.payload;
    },
  },
});

export const { setTime, setisRunning, reset, logTime } = stopwatchSlice.actions;

export default stopwatchSlice.reducer;
