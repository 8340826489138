import EditorTheme from "../themes/EditorTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";

import ToolbarPlugin from "../plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import ImagePlugin from "../plugins/ImagePlugin";

import ListMaxIndentLevelPlugin from "../plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "../plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "../plugins/AutoLinkPlugin";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { ImageNode } from "../nodes/ImageNode";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import HandleChangePlugin from "../plugins/HandleChangePlugin";

function Placeholder() {
  return <div className="editor-placeholder">Start writing...</div>;
}

const editorConfig = {
  theme: EditorTheme,
  namespace: "MyEditor",
  onError(error: any) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    ImageNode,
  ],
};

export default function EditorComponent() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: `2px ridge ${theme.palette.background.paper}`,
        borderRadius: "7px",
        background: theme.palette.background.default,
        lineHeight: "20px",
        fontWeight: "400",
        textAlign: "left",
        resize: "both",
        overflow: "auto",
        width: "700px",
        height: "500px",
      }}
    >
      <Box
        sx={{
          cursor: "move",
        }}
        id="handle"
      >
        <LexicalComposer initialConfig={editorConfig}>
          <ToolbarPlugin />
          <Box
            sx={{
              position: "relative",
              cursor: "text",
            }}
            id="stop-drag"
          >
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <ImagePlugin />
            <TabIndentationPlugin />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <HandleChangePlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <ClearEditorPlugin />
          </Box>
        </LexicalComposer>
      </Box>
    </Box>
  );
}
