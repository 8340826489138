import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Category, PdfViewerState } from "../types/types";
import {
  Content,
  IHighlight,
  ScaledPosition,
} from "@argument-studio/react-pdf-highlighter-with-categories";

const initialState: PdfViewerState = {
  url: "",
  data: null,
  highlights: [],
  categories: [
    { label: "Assumption", background: "#95c7e0" },
    { label: "Premise", background: "#609b91" },
    { label: "Target", background: "#ce7e8b" },
  ],
  id: "",
  title: "",
  path: "",
};

export const highlightsSlice = createSlice({
  name: "highlights",
  initialState,
  reducers: {
    addHighlight: (
      draft: PdfViewerState,
      action: PayloadAction<IHighlight>
    ) => {
      draft.highlights.push(action.payload);
    },
    deleteHighlight: (
      draft: PdfViewerState,
      action: PayloadAction<{ id: string }>
    ) => {
      const index = draft.highlights.findIndex(
        (highlight) => highlight.id === action.payload.id
      );
      if (index !== -1) draft.highlights.splice(index, 1);
    },
    changeHighlightCategory: (
      draft: PdfViewerState,
      action: PayloadAction<{ id: string; category: string }>
    ) => {
      const { id, category } = action.payload;
      const highlight = draft.highlights.find(
        (highlight) => highlight.id === id
      );
      if (!highlight) return;
      highlight.comment.category = category;
    },
    changeHighlightComment: (
      draft: PdfViewerState,
      action: PayloadAction<{ id: string; comment: string }>
    ) => {
      const { id, comment } = action.payload;
      const highlight = draft.highlights.find(
        (highlight) => highlight.id === id
      );
      if (!highlight) return;
      highlight.comment.text = comment;
    },
    resetHighlights: (draft: PdfViewerState) => {
      draft.highlights = [];
    },
    updateHighlight: (
      draft: PdfViewerState,
      action: PayloadAction<{
        id: string;
        position: Partial<ScaledPosition>;
        content: Partial<Content>;
      }>
    ) => {
      const { id, position, content } = action.payload;
      const highlight = draft.highlights.find(
        (highlight) => highlight.id === id
      );
      if (!highlight) return;

      const originalPosition = highlight.position;
      const originalContent = highlight.content;
      highlight.position = {
        ...originalPosition,
        ...position,
      };
      highlight.content = { ...originalContent, ...content };
    },
    setPdfUrl: (draft: PdfViewerState, action: PayloadAction<string>) => {
      draft.url = action.payload;
    },
    setPdfData: (
      draft: PdfViewerState,
      action: PayloadAction<Uint8Array | null>
    ) => {
      draft.data = action.payload;
    },
    deleteCategory: (
      draft: PdfViewerState,
      action: PayloadAction<{ category: string }>
    ) => {
      const index = draft.categories.findIndex(
        (cat) => cat.label === action.payload.category
      );
      if (index !== -1) {
        draft.categories.splice(index, 1);
      }
    },
    addCategory: (
      draft: PdfViewerState,
      action: PayloadAction<{ label: string; background: string }>
    ) => {
      const { label, background } = action.payload;
      draft.categories.push({
        label,
        background,
      });
    },
    overwriteCategories: (
      draft: PdfViewerState,
      action: PayloadAction<{ categories: Category[] }>
    ) => {
      draft.categories = action.payload.categories;
    },
    addDemoHighlights: (
      draft: PdfViewerState,
      action: PayloadAction<IHighlight[]>
    ) => {
      draft.highlights = action.payload;
    },
    selectFetchedHighlights: (
      draft: PdfViewerState,
      action: PayloadAction<any>
    ) => {
      draft.url = action.payload.url ?? initialState.url;
      draft.data = action.payload.data ?? initialState.data;
      draft.highlights = action.payload.highlighter ?? initialState.highlights;
      draft.categories = action.payload.categories ?? initialState.categories;
      draft.id = action.payload.id ?? initialState.id;
      draft.title = action.payload.title ?? initialState.title;
      draft.path = action.payload.path ?? initialState.path;
    },
  },
});

export const {
  addHighlight,
  deleteHighlight,
  changeHighlightCategory,
  changeHighlightComment,
  resetHighlights,
  updateHighlight,
  overwriteCategories,
  selectFetchedHighlights,
  setPdfData,
  setPdfUrl,
  addDemoHighlights,
} = highlightsSlice.actions;
export default highlightsSlice.reducer;
