import { useState, ReactNode } from "react";
import { useAppDispatch } from "../redux/reduxHooks";
import { useNavigate } from "react-router-dom";
import { Updater, useImmer } from "use-immer";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import {
  LightModeOutlined,
  DarkModeOutlined,
  ChevronLeft,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";

import logo from "../assets/logo.png";

import { changeMode } from "../redux/themeSlice";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  modals: (
    modalVisible: string,
    setModalVisible: Updater<string>
  ) => ReactNode[];
}

const drawerWidth = 240;

export default function MainNavbar({ modals }: Props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useImmer("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trigger = useScrollTrigger();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box
        sx={{
          background: theme.palette.primary.main,
          marginTop: -1,
        }}
      >
        <IconButton onClick={handleDrawerToggle} sx={{ float: "right" }}>
          <ChevronLeft />
        </IconButton>
        <Typography
          onClick={() => navigate("/")}
          variant="h5"
          sx={{ my: 1, paddingTop: 5 }}
        >
          AnnoGram
        </Typography>
        <Tooltip
          title={theme.palette.mode === "dark" ? "Toggle Light" : "Toggle Dark"}
        >
          <IconButton onClick={() => dispatch(changeMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ color: "#99c1fe" }} />
            ) : (
              <LightModeOutlined sx={{ color: " #FFFF9F" }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <List onClick={() => handleDrawerToggle()}>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => navigate("/")}
          >
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => navigate("/documentation")}
          >
            <ListItemText primary="Documentation" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => setModalVisible("contact")}
          >
            <ListItemText primary="Contact" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => {
              setModalVisible("register");
            }}
          >
            <ListItemText primary="Login" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => {
              setModalVisible("register");
            }}
          >
            <ListItemText primary="Register" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar component="nav" sx={{ boxShadow: "none", position: "static" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
              <IconButton onClick={() => navigate("/")}>
                <Box
                  component="img"
                  alt="logo"
                  src={logo}
                  height="32px"
                  width="32px"
                  borderRadius="25%"
                  sx={{ objectFit: "cover" }}
                />
              </IconButton>
            </Box>

            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Tooltip
                title={
                  theme.palette.mode === "dark" ? "Toggle Light" : "Toggle Dark"
                }
              >
                <IconButton onClick={() => dispatch(changeMode())}>
                  {theme.palette.mode === "dark" ? (
                    <DarkModeOutlined sx={{ color: "#99c1fe" }} />
                  ) : (
                    <LightModeOutlined sx={{ color: " #FFFF9F" }} />
                  )}
                </IconButton>
              </Tooltip>
              <Button
                sx={{ color: theme.palette.text.primary }}
                onClick={() => navigate("/")}
              >
                Home
              </Button>
              <Button
                sx={{ color: theme.palette.text.primary }}
                onClick={() => navigate("/documentation")}
              >
                Documentation
              </Button>
              <Button
                sx={{ color: theme.palette.text.primary }}
                onClick={() => setModalVisible("contact")}
              >
                Contact
              </Button>
              <Button
                sx={{ color: theme.palette.text.primary }}
                onClick={() => {
                  setModalVisible("login");
                }}
              >
                Login
              </Button>
              <Button
                sx={{ color: theme.palette.text.primary }}
                onClick={() => {
                  setModalVisible("register");
                }}
              >
                Register
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Slide>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {modals(modalVisible, setModalVisible)}
    </Box>
  );
}
