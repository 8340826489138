import { Vector2d } from "konva/lib/types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ContextMenuButtons } from "../types/types";

interface Props {
  menuCoords: Vector2d;
  menuButtons: ContextMenuButtons;
  open: boolean;
  onClose: () => void;
}

const ContextMenu = ({
  menuCoords: { x, y },
  menuButtons,
  open,
  onClose,
}: Props) => {
  return (
    <Menu
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{ left: x, top: y }}
      onClose={onClose}
    >
      {menuButtons.map(({ text, onClick }, idx) => (
        <MenuItem onClick={onClick} key={"menu-button-" + idx}>
          {text}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ContextMenu;
