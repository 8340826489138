import {
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import { Updater, useImmer } from "use-immer";
import {
  AppBar,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Toolbar,
  useTheme,
} from "@mui/material";
import {
  AccessAlarm,
  ArrowDropDownOutlined,
  AttachFileOutlined,
  DarkModeOutlined,
  LightModeOutlined,
  Menu as MenuIcon,
  MoreHorizOutlined,
  NotesOutlined,
  PersonOutlined,
  QuestionMark,
  Poll,
} from "@mui/icons-material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import FlexBetween from "../reusable/FlexBetween";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { clearAll, logout } from "../redux/authSlice";

import { changeMode } from "../redux/themeSlice";
import StyledSwitch from "./StyledSwitch";
import { updateAll } from "../services/supabaseAPI";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { scaleCanvas } from "../redux/canvasSlice";

interface Props {
  scale: string;
  setScale: Dispatch<SetStateAction<string>>;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  isNonMobile: boolean;
  isEditorOpen: boolean;
  setIsEditorOpen: Dispatch<SetStateAction<boolean>>;
  onExportClick: () => void;
  modals: (
    modalVisible: string,
    setModalVisible: Updater<string>
  ) => ReactNode[];
  tutorial: (
    showTutorialState: boolean,
    setShowTutorialState: Updater<boolean>
  ) => ReactNode;
  canvasOn: boolean;
  toggleCanvas: () => void;
}

const ArgNavbar = ({
  scale,
  setScale,
  isSidebarOpen,
  setIsSidebarOpen,
  isEditorOpen,
  setIsEditorOpen,
  onExportClick,
  modals,
  tutorial,
  canvasOn,
  toggleCanvas,
}: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const highlightState = useAppSelector((state) => state.highlights);
  const userState = useAppSelector((state) => state.user);
  const canvasState = useAppSelector((state) => state.canvas);
  const themeState = useAppSelector((state) => state.theme);
  const stopwatchState = useAppSelector((state) => state.stopwatch);
  const editorState = useAppSelector((state) => state.editor);
  const [modalVisible, setModalVisible] = useImmer("");
  const [showTutorialState, setShowTutorialState] = useImmer(false);
  const navigate = useNavigate();

  const [readerScaleOptions, setReaderScaleOptions] = useState<
    Array<{ label: string; value: string }>
  >([
    { label: "Actual Size", value: "page-actual" },
    { label: "Page Width", value: "page-width" },
    { label: "Fit Page", value: "page-fit" },
    { label: "Auto", value: "auto" },
    { label: "50%", value: "0.50" },
    { label: "100%", value: "1" },
    { label: "150%", value: "1.50" },
    { label: "200%", value: "2" },
  ]);
  const [canvasScaleOptions, setCanvasScaleOptions] = useState<
    Array<{ label: string; value: number }>
  >([
    {
      label: `${(canvasState.scale * 100).toFixed()}%`,
      value: canvasState.scale,
    },
    { label: "Maximum", value: 2 },
    { label: "Minimum", value: 0.1 },
  ]);

  const [profileAnchor, setProfileAnchor] = useState<any | null>(null);
  const isProfileOpen = Boolean(profileAnchor);
  const handleProfileClick = (event: MouseEvent<HTMLButtonElement>) =>
    setProfileAnchor(event.currentTarget);
  const handleProfileClose = () => setProfileAnchor(null);

  const [toolsAnchor, setToolsAnchor] = useState<any | null>(null);
  const isToolsOpen = Boolean(toolsAnchor);
  const handleToolsClick = (event: MouseEvent<HTMLButtonElement>) =>
    setToolsAnchor(event.currentTarget);
  const handleToolsClose = () => setToolsAnchor(null);

  const handleLogout = async () => {
    if (highlightState.id && canvasState.projectId) {
      await updateAll(canvasState, highlightState, editorState);
    }
    dispatch(clearAll());
    dispatch(logout());
    navigate("/");
  };

  const handleDemoQuit = () => {
    navigate("/");
    dispatch(clearAll());
  };
  const handleStopWatch = () => {
    setModalVisible("watch");
  };

  const handleTextEditor = () => {
    setIsEditorOpen(!isEditorOpen);
  };

  const handleToolSelect = (type: string) => {
    setModalVisible(type);
    handleToolsClose();
  };

  const handleScaleSelect = (event: SelectChangeEvent) => {
    if (canvasOn) {
      dispatch(scaleCanvas({ scale: parseFloat(event.target.value) }));
    } else {
      setScale(event.target.value);
    }
  };

  const handleScaleIncrease = () => {
    if (canvasOn && canvasState.scale < 2) {
      const newValue = Math.round((canvasState.scale + 0.05) * 100) / 100;

      setCanvasScaleOptions([
        { label: `${(newValue * 100).toFixed()}%`, value: newValue },
        { label: "Maximum", value: 2 },
        { label: "Minimum", value: 0.1 },
      ]);
      dispatch(scaleCanvas({ scale: newValue }));
    } else {
      if (
        scale === "page-actual" ||
        scale === "page-width" ||
        scale === "page-fit" ||
        scale === "auto"
      ) {
        setScale("1");
      } else {
        const newScaleOptions = readerScaleOptions.filter(
          (option) =>
            option.value === "page-actual" ||
            option.value === "page-width" ||
            option.value === "page-fit" ||
            option.value === "auto" ||
            parseFloat(option.value) > parseFloat(scale)
        );
        setReaderScaleOptions([
          ...newScaleOptions,
          {
            label: `${((parseFloat(scale) + 0.05) * 100).toFixed()}%`,
            value: (parseFloat(scale) + 0.05).toFixed(2).toString(),
          },
        ]);
        setScale((parseFloat(scale) + 0.05).toFixed(2).toString());
      }
    }
  };

  const handleScaleDecrease = () => {
    if (canvasOn && canvasState.scale > 0.1) {
      const newValue = Math.round((canvasState.scale - 0.05) * 100) / 100;

      setCanvasScaleOptions([
        { label: `${(newValue * 100).toFixed()}%`, value: newValue },
        { label: "Maximum", value: 2 },
        { label: "Minimum", value: 0.1 },
      ]);
      dispatch(scaleCanvas({ scale: newValue }));
    } else {
      if (
        scale === "page-actual" ||
        scale === "page-width" ||
        scale === "page-fit" ||
        scale === "auto"
      ) {
        setScale("1");
      } else if (parseFloat(scale) > 0.1) {
        const newScaleOptions = readerScaleOptions.filter(
          (option) =>
            option.value === "page-actual" ||
            option.value === "page-width" ||
            option.value === "page-fit" ||
            option.value === "auto" ||
            parseFloat(option.value) > parseFloat(scale)
        );
        setReaderScaleOptions([
          ...newScaleOptions,
          {
            label: `${((parseFloat(scale) - 0.05) * 100).toFixed()}%`,
            value: (parseFloat(scale) - 0.05).toFixed(2).toString(),
          },
        ]);
        setScale((parseFloat(scale) - 0.05).toFixed(2).toString());
      } else {
        return;
      }
    }
  };

  return (
    <AppBar>
      <Toolbar>
        {/* Left Side */}
        <FlexBetween sx={{ marginRight: "auto" }}>
          <Tooltip title={isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}>
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <MenuIcon />
            </IconButton>
          </Tooltip>
        </FlexBetween>
        {/* Center */}
        <FlexBetween marginLeft={isSidebarOpen ? "35vw" : "auto"}>
          <Tooltip title="Zoom Out">
            <IconButton onClick={handleScaleDecrease}>
              <ZoomOutIcon />
            </IconButton>
          </Tooltip>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel id="select-zoom">Zoom Level</InputLabel>
            <Select
              labelId="select-zoom"
              id="select-zoom"
              value={canvasOn ? canvasState.scale.toString() : scale}
              label="Zoom Level"
              onChange={handleScaleSelect}
            >
              {canvasOn
                ? canvasScaleOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                : readerScaleOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <Tooltip title="Zoom In">
            <IconButton onClick={handleScaleIncrease}>
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
        </FlexBetween>
        {/* Right Side */}
        <FlexBetween
          gap={isSidebarOpen ? "0.5rem" : "1.5rem"}
          sx={{ marginLeft: "auto" }}
        >
          <Tooltip title="Provide Feedback">
            <IconButton
              onClick={() =>
                window.open("https://forms.gle/QmW5XQamuJB2s2hE9", "_blank")
              }
            >
              <Poll />
            </IconButton>
          </Tooltip>
          <FormControl onChange={toggleCanvas}>
            <StyledSwitch checked={canvasOn} />
          </FormControl>
          <Tooltip title="Text Editor">
            <IconButton onClick={handleTextEditor}>
              <NotesOutlined sx={{ color: isEditorOpen ? "#3DED97" : "" }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={themeState.mode === "dark" ? "Toggle Light" : "Toggle Dark"}
          >
            <IconButton onClick={() => dispatch(changeMode())}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlined sx={{ color: "#99c1fe" }} />
              ) : (
                <LightModeOutlined sx={{ color: " #FFFF00" }} />
              )}
            </IconButton>
          </Tooltip>
          {userState.userId ? (
            <>
              <Tooltip title="Timer">
                <IconButton
                  onClick={handleStopWatch}
                  sx={{ color: stopwatchState.isRunning ? "#3DED97" : "" }}
                >
                  <AccessAlarm />
                </IconButton>
              </Tooltip>
              <Tooltip title="Upload/Open Document">
                <IconButton onClick={() => navigate("/documents")}>
                  <AttachFileOutlined />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            " "
          )}
          <Tooltip title="Instructions">
            <IconButton onClick={() => setShowTutorialState(true)}>
              <QuestionMark />
            </IconButton>
          </Tooltip>
          <Box>
            <Tooltip title="More Tools">
              <IconButton onClick={handleToolsClick}>
                <MoreHorizOutlined />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={toolsAnchor}
              open={isToolsOpen}
              onClose={handleToolsClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={() => handleToolSelect("editCategories")}>
                Edit Categories
              </MenuItem>
              <MenuItem onClick={onExportClick}>Export Canvas</MenuItem>
              <MenuItem
                onClick={() => handleToolSelect("resetHighlights")}
                disabled={canvasOn}
              >
                Reset Highlights
              </MenuItem>
              <MenuItem
                onClick={() => handleToolSelect("resetCanvas")}
                disabled={!canvasOn}
              >
                Reset Canvas
              </MenuItem>
            </Menu>
          </Box>
          <Box>
            <Button onClick={handleProfileClick}>
              <PersonOutlined sx={{ color: theme.palette.secondary.main }} />
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary.main, fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={profileAnchor}
              open={isProfileOpen}
              onClose={handleProfileClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              {userState.userId ? (
                <div>
                  <MenuItem onClick={() => navigate("/profile")}>
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                </div>
              ) : (
                <MenuItem onClick={handleDemoQuit}>Register</MenuItem>
              )}
            </Menu>
          </Box>
        </FlexBetween>
      </Toolbar>
      {modals(modalVisible, setModalVisible)}
      {tutorial(showTutorialState, setShowTutorialState)}
    </AppBar>
  );
};

export default ArgNavbar;
