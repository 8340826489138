import { ChangeEvent } from "react";
import emailjs from "emailjs-com";
import { Dispatch, SetStateAction } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import { toast } from "react-toastify";

interface Props {
  setModalVisible: Dispatch<SetStateAction<string>>;
}

export default function ContactForm({ setModalVisible }: Props) {
  const theme = useTheme();

  const sendEmail = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_bjfixaq",
        "template_k8erqmi",
        e.target,
        "YCgJN0RiQuvCapejL"
      )
      .then(
        () => {
          toast.success("Message sent, we'll get back to you shortly");
        },
        (error) => {
          toast.error(error.text);
        }
      );
    e.target.reset();
    setModalVisible("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <Box
        component="form"
        onSubmit={sendEmail}
        noValidate
        sx={{
          marginTop: "15px",
          marginX: "40px",
        }}
      >
        <TextField
          margin="normal"
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="subject"
          label="Subject"
          type="text"
          id="subject"
        />
        <TextField
          multiline
          minRows={3}
          name="message"
          style={{
            width: "100%",
            marginTop: 15,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
          }}
          placeholder="Write your message here..."
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
}
