import TutorialItem from "./TutorialItem";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../redux/reduxHooks";
import { useTheme } from "@mui/material";
interface Props {
  showTutorialState: boolean;
  setShowTutorialState: (showTutorialState: boolean) => void;
}

const Tutorial = ({ showTutorialState, setShowTutorialState }: Props) => {
  const theme = useTheme();
  const { userId } = useAppSelector((state) => state.user);
  const handleClose = () => setShowTutorialState(false);

  return (
    <Drawer
      anchor="right"
      open={showTutorialState}
      onClose={handleClose}
      id="tutorial"
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: 500,
          background: theme.palette.background.paper,
        },
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleClose}>
          <ChevronRight />
        </IconButton>
        <Typography fontSize={24} marginRight={14}>
          AnnoGram Tutorial
        </Typography>
      </Toolbar>
      <Box margin={2}>
        <Typography fontSize={16}>
          The items below provide short instructional videos for the main
          features of AnnoGram. You can also access more in-depth explanations
          by reading the{" "}
          {userId ? (
            <NavLink to={"/help"}>documentation</NavLink>
          ) : (
            <NavLink to={"/documentation"}>documentation</NavLink>
          )}
          .
        </Typography>
      </Box>
      <Box>
        <TutorialItem
          sectionTitle="Importing Documents"
          sectionEventKey="0"
          imgTitle=""
          imgSrc="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Pics/Upload-Open.png?t=2023-04-20T18%3A40%3A33.911Z"
          imgAlt="A clickable image depicting the pdf import menu"
          imgCaption="Click the 'Import' button in the 'Tools' dropdown to bring up to import menu. You can import documents from local files and urls."
          gif="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Vids/Upload_Open.mp4?t=2023-04-20T18%3A41%3A19.576Z"
          gifAlt="Video depicting how to import files."
        />
        <TutorialItem
          sectionTitle="Highlighting Text"
          sectionEventKey="1"
          imgTitle=""
          imgSrc="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Pics/Text_Highlight.png?t=2023-04-20T18%3A41%3A55.936Z"
          imgAlt="A clickable image displaying highlighted text"
          imgCaption="Click and drag your cursor from the starting to the ending text you want to highlight. A popup menu will appear that will allow you to add a comment and select a category for your highlight."
          gif="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Vids/Text_Highlight.mp4?t=2023-04-20T18%3A42%3A22.514Z"
          gifAlt="Video depicting how to make text highlights"
        />
        <TutorialItem
          sectionTitle="Highlighting Images and Diagrams"
          sectionEventKey="2"
          imgTitle=""
          imgSrc="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Pics/Image_Highlight.png?t=2023-04-20T18%3A42%3A44.984Z"
          imgAlt="A clickable image displaying an area highlight"
          imgCaption="Hold down the option/alt key (&#8997;), and then drag your cursor accross the location you want to highlight. A popup menu will appear that will allow you to add a comment and select a category for your highlight."
          gif="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Vids/Image_Highlight.mp4?t=2023-04-20T18%3A43%3A02.745Z"
          gifAlt="Video depicting how to add an area highlight"
        />
        <TutorialItem
          sectionTitle="Editing Category Labels"
          sectionEventKey="3"
          imgTitle=""
          imgSrc="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Pics/Edit_Categories.png?t=2023-04-20T18%3A43%3A25.218Z"
          imgAlt="A clickable image displaying the edit category labels tool"
          imgCaption="Click the 'Edit Category' button in the 'Tools' dropdown to add/edit/delete category labels."
          gif="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Vids/Edit_Categories.mp4?t=2023-04-20T18%3A43%3A39.336Z"
          gifAlt="Video depicting how to edit category labels"
        />
        <TutorialItem
          sectionTitle="Diagramming Arguments on the Canvas"
          sectionEventKey="4"
          imgTitle=""
          imgSrc="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Pics/Canvas_Demo.png?t=2023-04-20T18%3A44%3A01.088Z"
          imgAlt="A clickable image displaying the argument canvas"
          imgCaption="Click the 'Canvas On/Off' button to bring up the canvas. The canvas allows you to diagram arguments using highlights from the sidebar. You can also create your own argument nodes by double clicking on the background of the canvas. Watch the video to get a sense of all you can do using the canvas."
          gif="https://zkvfrdihqzuxnfywghlj.supabase.co/storage/v1/object/public/App-Demo-Vids/Canvas_Demo.mp4?t=2023-04-20T18%3A44%3A13.734Z"
          gifAlt="Video depicting how to edit category labels"
        />
      </Box>
    </Drawer>
  );
};

export default Tutorial;
