import React from "react";
import { Container, Link, Typography } from "@mui/material";

interface Props {
  setValue: React.Dispatch<React.SetStateAction<number>>;
}
const TermsOfService: React.FC<Props> = ({ setValue }) => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        AnnoGram Terms of Service
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Last Updated: June 6, 2023
      </Typography>
      <Typography variant="h5" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing and using the AnnoGram software as a service (SaaS)
        application (the “Service”), you are agreeing to be bound by these Terms
        of Service. If you do not agree to these terms, please do not use the
        Service.
      </Typography>
      <Typography variant="h5" gutterBottom>
        2. Description of Service
      </Typography>
      <Typography variant="body1" paragraph>
        AnnoGram provides a platform for reading and annotating documents,
        diagramming, argument structuring, collaboration, and writing.
      </Typography>
      <Typography variant="h5" gutterBottom>
        3. Eligibility
      </Typography>
      <Typography variant="body1" paragraph>
        The Service is available to users who are under 18 years of age and
        reside in the United States or Canada. By using the Service, you
        represent that you meet these requirements.
      </Typography>
      <Typography variant="h5" gutterBottom>
        4. Account Registration
      </Typography>
      <Typography variant="body1" paragraph>
        To use AnnoGram, you will need to create an account. You are responsible
        for all activities that occur under your account and for keeping your
        account password secure.
      </Typography>
      <Typography variant="h5" gutterBottom>
        5. Prohibited Activities
      </Typography>
      <Typography variant="body1" paragraph>
        By using the Service, you agree not to:
        <ul>
          <li>
            Use the Service for any illegal purpose or in violation of any laws
            or regulations.
          </li>
          <li>
            Violate or infringe other people's intellectual property, privacy,
            or other rights.
          </li>
          <li>
            Use our services to advertise or offer to sell goods and services.
          </li>
          <li>Distribute malware or other malicious code.</li>
          <li>Attempt to access accounts or data that are not yours.</li>
          <li>Harass or harm another person or group.</li>
          <li>
            Disrupt or interfere with the security or use of our Service or any
            websites linked to them.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" gutterBottom>
        6. User Content
      </Typography>
      <Typography variant="body1" paragraph>
        By using our Services, you may provide us with certain types of
        information, files, and content (collectively, "User Content"). You
        retain full ownership of your User Content. We do not claim any
        ownership rights to your User Content.
        <br />
        <br />
        However, in order to provide the Services, we need your permission to
        process your User Content. For example, we may need to store your files,
        display them to you and others who you choose to share them with, and
        create previews of your content. This permission extends to our trusted
        third-party partners, such as the Supabase Platform or Amazon Web
        Services, who provide services to us that support the delivery of our
        Services.
        <br />
        <br />
        Except for the rare exceptions identified in our{" "}
        <Link
          color="#50c878"
          onClick={() => setValue(1)}
          sx={{ cursor: "pointer" }}
        >
          Privacy Policy
        </Link>
        , we will not share your User Content with third parties unless you
        direct us to. Your responsibility to your User Content also includes
        ensuring that you have the necessary rights and permissions to use and
        share your User Content and that your User Content and your use of the
        Services complies with these Terms.
        <br />
        <br />
        While we may review public User Content for compliance with community
        guidelines, we have no obligation to monitor or review any User Content.
        We are not responsible for the accuracy, completeness, appropriateness,
        or legality of your User Content or any other information you may be
        able to access using the Services.
        <br />
        <br />
        We reserve the right to modify, suspend, or discontinue the Services at
        any time. If we do so, we understand that you may want to retrieve your
        User Content. If you have registered for an account, we will make
        reasonable efforts to notify you and will aim to keep your User Content
        accessible in a read-only format for up to three months after the
        Services are suspended or discontinued.
      </Typography>
      <Typography variant="h5" gutterBottom>
        7. Links to Other Websites
      </Typography>
      <Typography variant="body1" paragraph>
        Our Service may contain links to third-party websites or services that
        are not owned or controlled by AnnoGram.
      </Typography>
      <Typography variant="h5" gutterBottom>
        8. Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Our collection and use of personal information is outlined in our{" "}
        <Link
          color="#50c878"
          onClick={() => setValue(1)}
          sx={{ cursor: "pointer" }}
        >
          Privacy Policy
        </Link>
        .
      </Typography>
      <Typography variant="h5" gutterBottom>
        9. Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" paragraph>
        The Service is provided “AS IS” and “AS AVAILABLE”, at your own risk,
        without express or implied warranty or condition of any kind. We
        disclaim any warranties of merchantability, fitness for a particular
        purpose, freedom from errors, and non-infringement.
      </Typography>
      <Typography variant="h5" gutterBottom>
        10. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        To the fullest extent permitted by law, AnnoGram, its affiliates,
        officers, employees, agents, suppliers or licensors will not be liable
        for any indirect, special, incidental, punitive, exemplary or
        consequential damages, loss of profits, revenue, business or savings,
        depletion of goodwill and/or similar losses, or loss or corruption of
        data.
      </Typography>
      <Typography variant="h5" gutterBottom>
        11. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        Company makes no guaranty of confidentiality or privacy of any
        communication or information transmitted on the Site or any website
        linked to the Site. Company will not be liable for the privacy of email
        addresses, registration and identification information, disk space,
        communications, confidential or trade-secret information.
      </Typography>
      <Typography variant="h5" gutterBottom>
        12. Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to indemnify, defend, and hold harmless AnnoGram, its
        affiliates, officers, employees, agents, suppliers and licensors from
        any claims, including losses, arising out of any breach of these Terms
        by you.
      </Typography>
      <Typography variant="h5" gutterBottom>
        13. Dispute Resolution
      </Typography>
      <Typography variant="body1" paragraph>
        Any disputes arising out of or relating to these Terms or the Service
        will be resolved through litigation in the state of Delaware, United
        States, and you consent to the jurisdiction of such courts.
      </Typography>
      <Typography variant="h5" gutterBottom>
        14. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about these Terms, please contact us at{" "}
        <Link color="#50c878" href="mailto:support@annogram.io">
          support@annogram.io
        </Link>
      </Typography>

      <Typography variant="body1" paragraph>
        By using the Service, you acknowledge that you have read these Terms,
        understand them, and agree to be bound by them.
      </Typography>
    </Container>
  );
};

export default TermsOfService;
