import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { CustomTextField } from "../reusable/CustomTextField";
import { Button, Container, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  emailSchema,
  nameSchema,
  passwordSchema,
} from "../utilities/yupScehma";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import Divider from "@mui/material/Divider";
import FlexBetween from "../reusable/FlexBetween";
import TextField from "@mui/material/TextField";
import {
  changeEmail,
  changeFirstName,
  changeLastName,
  changePassword,
} from "../services/supabaseAPI";
import { addUserInfo } from "../redux/authSlice";
import { toast } from "react-toastify";
import DeleteAccountModal from "../admin-panel/DeleteAccountModal";

interface Name {
  firstName: string;
  lastName: string;
}

interface Email {
  email: string;
}

interface Password {
  newPassword: string;
  confirmPassword: string;
}
const ProfileScreen = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
  } = useForm<Password>({
    resolver: yupResolver(passwordSchema),
  });

  const {
    register: registerE,
    handleSubmit: handleSubmitE,
    reset: resetE,
    formState: formStateE,
    formState: { errors: errorsE },
  } = useForm<Email>({
    resolver: yupResolver(emailSchema),
  });

  const {
    register: registerN,
    handleSubmit: handleSubmitN,
    reset: resetN,
    formState: formStateN,
    formState: { errors: errorsN },
  } = useForm<Name>({
    resolver: yupResolver(nameSchema),
  });

  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state) => state.auth);
  const { userId } = useAppSelector((state) => state.user);
  const [modalVisible, setModalVisible] = useState(false);

  const submitPassword = async (data: Password) => {
    const response = await changePassword(data.newPassword);

    if (response) {
      toast.success("Password Changed Successfully");
    } else {
      toast.error("Something went wrong: please try again");
    }
  };

  const submitEmail = async (data: Email) => {
    const response = await changeEmail(data.email);

    if (response) {
      toast.success("We've sent you an email: please confirm your address");
    } else {
      toast.error("Something went wrong: please try again");
    }
  };

  const submitName = async (data: Name) => {
    if (userId) {
      if (data.lastName) {
        await changeLastName(data.lastName, userId);
      }
      if (data.firstName) {
        await changeFirstName(data.firstName, userId);
      }
      dispatch(addUserInfo(userId));
    }
  };

  useEffect(() => {
    if (userId) {
      dispatch(addUserInfo(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ newPassword: "", confirmPassword: "" });
    }

    if (formStateE.isSubmitSuccessful) {
      resetE({ email: "" });
    }

    if (formStateN.isSubmitSuccessful) {
      resetN({ firstName: "", lastName: "" });
    }
  }, [formState, reset, formStateE, resetE, formStateN, resetN]);

  return (
    <Box>
      <Typography align="center" variant="h1" sx={{ marginY: "15px" }}>
        Profile Settings
      </Typography>
      <Container sx={{ marginTop: "100px" }}>
        <FlexBetween>
          <Typography variant="h3">Email</Typography>
          <Typography variant="h3">Organization and Role</Typography>
        </FlexBetween>
        <Divider />
        <FlexBetween>
          <Typography>
            {userInfo?.email ?? "currentemail@annogram.com"}
          </Typography>
          <Typography>{}</Typography>
        </FlexBetween>
        <Box marginY="5px">
          <form onSubmit={handleSubmitE(submitEmail)}>
            <TextField
              {...registerE("email")}
              name="email"
              label="New Email"
              error={!!errorsE.email}
              helperText={errorsE.email && errorsE?.email?.message}
              variant="standard"
              sx={{ width: "25%" }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2, ml: 1 }}
            >
              Submit
            </Button>
          </form>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginY: "20px",
          }}
        >
          <Box sx={{ width: "45%" }}>
            <Typography variant="h3">Update Profile Info</Typography>
            <form onSubmit={handleSubmitN(submitName)}>
              <CustomTextField
                {...registerN("firstName")}
                name="firstName"
                label={userInfo?.first_name ?? "First Name"}
                error={!!errorsN.firstName}
                helperText={errorsN.firstName && errorsN?.firstName?.message}
              />
              <CustomTextField
                {...registerN("lastName")}
                name="lastName"
                label={userInfo?.last_name ?? "Last Name"}
                error={!!errorsN.lastName}
                helperText={errorsN.lastName && errorsN?.lastName?.message}
              />
              <FlexBetween sx={{ gap: "5px" }}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Submit
                </Button>
                <Button
                  fullWidth
                  type="button"
                  variant="contained"
                  onClick={() => resetN()}
                  sx={{ mt: 2, mb: 2 }}
                >
                  Clear
                </Button>
              </FlexBetween>
            </form>
          </Box>
          <Box sx={{ width: "45%" }}>
            <Typography variant="h3">Update Your Password</Typography>
            <form onSubmit={handleSubmit(submitPassword)}>
              <CustomTextField
                {...register("newPassword")}
                name="newPassword"
                label="New Password"
                type="password"
                error={!!errors.newPassword}
                helperText={errors.newPassword && errors?.newPassword?.message}
              />
              <CustomTextField
                {...register("confirmPassword")}
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                error={!!errors.confirmPassword}
                helperText={
                  errors.confirmPassword && errors?.confirmPassword?.message
                }
              />
              <FlexBetween sx={{ gap: "5px" }}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Submit
                </Button>
                <Button
                  fullWidth
                  type="button"
                  variant="contained"
                  onClick={() => reset()}
                  sx={{ mt: 2, mb: 2 }}
                >
                  Clear
                </Button>
              </FlexBetween>
            </form>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", marginTop: "15px", alignItems: "center" }}>
          <Typography variant="body1">Need to leave?</Typography>
          <Button
            color="error"
            sx={{ marginLeft: "10px", textTransform: "none" }}
            variant="text"
            onClick={() => setModalVisible(true)}
          >
            <Typography variant="body1">Delete your account</Typography>
          </Button>
        </Box>
      </Container>
      <DeleteAccountModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        user={userInfo?.email ?? ""}
      />
    </Box>
  );
};

export default ProfileScreen;
