export const themeSettings = (mode: any) => {
  return {
    palette: {
      mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: "#2d2c31",
            },
            secondary: {
              main: "#a792c9",
            },
            background: {
              default: "#2f2e2e",
              paper: "#212121",
            },
          }
        : {
            primary: {
              main: "#c7c7c7",
            },
            secondary: {
              main: "#9575cd",
            },
            background: {
              default: "#d3d3d3",
              paper: "#e5e5e5",
            },
          }),
    },
    typography: {
      fontFamily: ["Fira Code", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Fira Code", "sans-serif"].join(","),
        fontSize: 50,
      },
      h2: {
        fontFamily: ["Fira Code", "sans-serif"].join(","),
        fontSize: 36,
      },
      h3: {
        fontFamily: ["Fira Code", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Fira Code", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Fira Code", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Fira Code", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
