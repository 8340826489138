import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

import authReducer from "./authSlice";
import highlightsReducer from "./highlightsSlice";
import canvasReducer from "./canvasSlice";
import canvasAuxReducer from "./canvasAuxSlice";
import themeReducer from "./themeSlice";
import stopwatchReducer from "./stopwatchSlice";
import userSlice from "./userSlice";
import editorReducer from "./editorSlice";

const rootReducer = combineReducers({
  highlights: highlightsReducer,
  canvas: canvasReducer,
  canvasAux: canvasAuxReducer,
  editor: editorReducer,
  auth: authReducer,
  theme: themeReducer,
  stopwatch: stopwatchReducer,
  user: userSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "highlights", "canvas", "editor", "theme"],
};

const resettableRootReducer = (
  state: ReturnType<typeof rootReducer> | undefined,
  action: AnyAction
) => {
  if (action.type === "auth/clearAll") {
    storage.removeItem("persist:root").catch(console.error);
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, resettableRootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
