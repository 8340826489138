import React, { useState, Dispatch, SetStateAction } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  useTheme,
  LinearProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { loginSchema } from "../utilities/yupScehma";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomTextField } from "../reusable/CustomTextField";
import { clearAll, logout } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { deleteUserAndTheirData } from "../services/supabaseAPI";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { supabase } from "../utilities/supabaseClient";
interface User {
  email: string;
  password: string;
}

interface Props {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  user: string;
}

const DeleteAccountModal = ({ modalVisible, setModalVisible, user }: Props) => {
  const [checked, setChecked] = useState(false);
  const [deletingData, setDeletingData] = useState(false);

  const userId = useAppSelector((state) => state.user.userId);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(loginSchema),
  });

  //The goodbye route is blocked by AuthContext.tsx
  const submitForm = async (data: User) => {
    if (!checked) {
      return;
    }
    const { error } = await supabase.auth.signInWithPassword(data);
    if (error) {
      toast.error("Invalid login credentials", { toastId: "1" });
      reset();
      return;
    }
    setDeletingData(true);
    await deleteUserAndTheirData(userId);
    dispatch(clearAll());
    dispatch(logout());
    navigate("/goodbye");
  };
  const handleCancel = () => {
    setModalVisible(false);
    setChecked(false);
    reset();
  };

  return (
    <Dialog
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      sx={{
        "& .MuiDialog-paper": {
          background: theme.palette.background.paper,
        },
      }}
    >
      <DialogTitle>
        {deletingData
          ? "Please wait while we delete your account and associated data"
          : "Delete Your Account"}
      </DialogTitle>
      {deletingData ? (
        <Box sx={{ width: "300px" }}>
          <LinearProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submitForm)}>
          <DialogContent>
            <DialogContentText>
              To confirm your account deletion, please enter your email and
              password below:
            </DialogContentText>
            <CustomTextField
              autoFocus
              {...register("email")}
              name="email"
              label="Email"
              error={!!errors.email}
              helperText={errors.email && errors?.email?.message}
              margin="dense"
              fullWidth
            />
            <CustomTextField
              {...register("password")}
              name="password"
              label="Password"
              type="password"
              error={!!errors.password}
              helperText={errors.password && errors?.password?.message}
              margin="dense"
              fullWidth
            />
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
              }
              label={
                <Typography>
                  I acknowledge that this will permanently erase the account and
                  all related data associated with {user}.
                </Typography>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" color="secondary" disabled={!checked}>
              Delete
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default DeleteAccountModal;
