import { useState, MouseEvent } from "react";
import type { IHighlight } from "@argument-studio/react-pdf-highlighter-with-categories";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  useTheme,
} from "@mui/material";

interface Props {
  categoryFilter: string;
  highlight: IHighlight;
  categories: { label: string; background: string }[];
  handleContextMenu: (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void;
  handleDragStart: () => void;
}

const updateHash = (id: string) => {
  document.location.hash = `highlight-${id}`;
};

const getHoverStyle = (mouseOver: boolean, backgroundColor?: string) => {
  if (mouseOver) {
    return {
      border: `3px solid ${backgroundColor ?? "#ddcc77"}`,
      boxShadow: `0px 10px 15px 2px ${backgroundColor ?? "#ddcc77"}`,
      transform: "translateY(-7px)",
      borderRadius: "10px",
    };
  }
  return {
    border: "none",
    boxShadow: "none",
    transform: "none",
    borderRadius: "none",
  };
};

const getFilterClassName = (categoryFilter: string, category: string) => {
  if (categoryFilter === "None" || category === categoryFilter) {
    return "sidebar__highlight";
  }
  return "hidden";
};

function SidebarListItem({
  highlight,
  categories,
  categoryFilter,
  handleContextMenu,
  handleDragStart,
}: Props) {
  const {
    id,
    content: { text, image },
    comment: { category, text: commentText },
    position: { pageNumber },
  } = highlight;

  const theme = useTheme();

  const [mouseOver, setMouseOver] = useState(false);

  const backgroundColor = categories.find(
    ({ label }) => category === label
  )?.background;

  return (
    <Card
      className={getFilterClassName(categoryFilter, category)}
      draggable
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onDragStart={handleDragStart}
      onClick={() => updateHash(id)}
      onContextMenu={handleContextMenu}
      sx={{
        ...getHoverStyle(mouseOver, backgroundColor),
        background: theme.palette.background.default,
      }}
    >
      <CardHeader title={commentText} />
      {text ? (
        <CardContent sx={{ paddingY: "0" }}>
          <blockquote>{`${text.slice(0, 90).trim()}…`}</blockquote>
        </CardContent>
      ) : (
        <CardMedia
          component="img"
          image={image}
          alt={"Screenshot"}
          sx={{ maxHeight: "300px", maxWidth: "300px", margin: "auto" }}
        />
      )}
      <Box
        sx={{
          margin: "0.5rem 1rem 0.5rem 0.5rem",
          textAlign: "right",
          fontSize: "10px",
        }}
      >
        Page {pageNumber}
      </Box>
    </Card>
  );
}

export default SidebarListItem;
