import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { registrationSchema } from "../utilities/yupScehma";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import { CustomTextField } from "../reusable/CustomTextField";
import { useAppDispatch } from "../redux/reduxHooks";
import { signup } from "../redux/authSlice";

interface MyForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUpForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MyForm>({
    resolver: yupResolver(registrationSchema),
  });
  const dispatch = useAppDispatch();
  const submitForm = async (data: MyForm) => {
    //await supaAuth.register(data);
    dispatch(signup(data));
  };

  return (
    <Box
      sx={{
        marginTop: "15px",
        marginX: "40px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <CustomTextField
          {...register("firstName")}
          name="firstName"
          label="First Name"
          error={!!errors.firstName}
          helperText={errors.firstName && errors?.firstName?.message}
        />
        <CustomTextField
          {...register("lastName")}
          name="lastName"
          label="Last Name"
          error={!!errors.lastName}
          helperText={errors.lastName && errors?.lastName?.message}
        />
        <CustomTextField
          {...register("email")}
          name="email"
          label="Email"
          error={!!errors.email}
          helperText={errors.email && errors?.email?.message}
        />
        <CustomTextField
          {...register("password")}
          name="password"
          label="Password"
          type="password"
          error={!!errors.password}
          helperText={errors.password && errors?.password?.message}
        />
        <CustomTextField
          {...register("confirmPassword")}
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          error={!!errors.confirmPassword}
          helperText={
            errors.confirmPassword && errors?.confirmPassword?.message
          }
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default SignUpForm;
