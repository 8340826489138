import { useState, MouseEvent } from "react";
import { ContextMenuButtons } from "../types/types";
import "../style/ContextMenu.css";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import {
  changeHighlightCategory,
  changeHighlightComment,
  deleteHighlight,
} from "../redux/highlightsSlice";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContextMenu from "../reusable/ContextMenu";
import { Vector2d } from "konva/lib/types";

interface Props {
  coords: Vector2d;
  menuTargetId: string;
  closeMenu: () => void;
}

const SidebarContextMenu = ({ coords, menuTargetId, closeMenu }: Props) => {
  const { categories, highlights } = useAppSelector(
    (state) => state.highlights
  );
  const dispatch = useAppDispatch();
  const [currentCategory, setCurrentCategory] = useState<string>("");
  const [commentValue, setCommentValue] = useState("");

  const [categoryAnchor, setCategoryAnchor] = useState<HTMLLIElement | null>(
    null
  );
  const isCategoryOpen = Boolean(categoryAnchor);
  const [isCommentOpen, setIsCommentOpen] = useState(false);

  const handleCategoryClick = (event: MouseEvent<HTMLLIElement>) => {
    setIsCommentOpen(false);
    setCurrentCategory(getCurrentCategoryName());
    setCategoryAnchor(event.currentTarget);
  };

  const handleCommentClick = (event: MouseEvent<HTMLLIElement>) => {
    setCommentValue(getCurrentComment());
    setCategoryAnchor(event.currentTarget);
    setIsCommentOpen(true);
  };

  const handleHighlightDelete = () => {
    dispatch(deleteHighlight({ id: menuTargetId }));
    closeMenu();
  };

  const getCurrentCategoryName = (): string => {
    const highlight = highlights.find(
      (highlight) => highlight.id === menuTargetId
    );
    if (!highlight) return "Default";
    return highlight.comment.category;
  };

  const getCurrentComment = () => {
    const highlight = highlights.find(
      (highlight) => highlight.id === menuTargetId
    );
    if (!highlight) return "";
    return highlight.comment.text;
  };

  const handleHighlightEdit = (category: string) => {
    setCurrentCategory(category);
    dispatch(
      changeHighlightCategory({
        id: menuTargetId,
        category,
      })
    );
    closeMenu();
  };

  const handleCommentEdit = (comment: string) => {
    dispatch(changeHighlightComment({ id: menuTargetId, comment }));
    closeMenu();
  };

  const menuButtons: ContextMenuButtons = [
    { text: "Edit Comment", onClick: handleCommentClick },
    { text: "Delete Highlight", onClick: handleHighlightDelete },
    { text: "Change Category", onClick: handleCategoryClick },
  ];

  return (
    <>
      <ContextMenu
        menuCoords={coords}
        menuButtons={menuButtons}
        open={true}
        onClose={closeMenu}
      />
      <Menu
        anchorEl={categoryAnchor}
        open={isCategoryOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ direction: "flex" }}
      >
        <IconButton
          sx={{ position: "absolute", top: "0px", right: "0px" }}
          onClick={closeMenu}
        >
          <CloseIcon sx={{ fontSize: "13px" }} />
        </IconButton>
        {isCommentOpen ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "15px",
              maxWidth: "30vw",
              minWidth: "20vw",
            }}
          >
            <TextField
              sx={{ padding: "5px" }}
              fullWidth
              variant="outlined"
              type="text"
              value={commentValue}
              multiline
              placeholder={commentValue ? commentValue : "Add a comment..."}
              onChange={(e) => setCommentValue(e.target.value)}
            />
            <Box
              sx={{
                flexDirection: "row",
                padding: "5px",
              }}
            >
              <Button
                size="small"
                onClick={() => handleCommentEdit(commentValue)}
                variant="outlined"
                color="secondary"
                sx={{ marginRight: "2px" }}
              >
                Submit
              </Button>
              <Button
                size="small"
                onClick={closeMenu}
                variant="outlined"
                color="secondary"
                sx={{ marginLeft: "2px" }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <RadioGroup
            sx={{
              display: "flex",
              marginLeft: "5px",
              marginTop: "10px",
              maxWidth: "200px",
            }}
            value={currentCategory}
          >
            {categories.map(({ label, background }) => {
              return (
                <FormControlLabel
                  key={label}
                  value={label}
                  control={
                    <Radio
                      key={label}
                      value={label}
                      sx={{
                        color: background,
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={() => handleHighlightEdit(label)}
                    />
                  }
                  label={label}
                />
              );
            })}
          </RadioGroup>
        )}
      </Menu>
    </>
  );
};

export default SidebarContextMenu;
