import { supabase } from "../utilities/supabaseClient";
import { CanvasState } from "../types/canvasTypes";
import { PdfViewerState } from "../types/types";

export const updateAll = async (
  canvasState: CanvasState,
  documentState: PdfViewerState,
  editorState: any
) => {
  await supabase
    .from("documents")
    .update({
      highlights: JSON.stringify(documentState.highlights),
      categories: JSON.stringify(documentState.categories),
    })
    .eq("document_id", documentState.id);

  await supabase
    .from("projects")
    .update({
      canvas: JSON.stringify(canvasState),
    })
    .eq("project_id", canvasState.projectId);

  await supabase
    .from("editor")
    .update({
      editor_state: JSON.stringify(editorState.editorState),
    })
    .eq("editor_id", editorState.editorId);
};

export const deleteStoredFiles = async (paths: string[]) => {
  const { error } = await supabase.storage.from("pdfs").remove(paths);
  if (error) {
    return error;
  }
};

export const deleteDocuments = async (documentIds: string[]) => {
  const { error } = await supabase
    .from("documents")
    .delete()
    .in("document_id", documentIds);
  if (error) {
    return error;
  }
};

export const uploadFile = async (
  ownerId: string,
  fileName: string,
  file: any
) => {
  const path = `${ownerId}/${fileName}`;
  const { error: storageError } = await supabase.storage
    .from("pdfs")
    .upload(path, file, {
      cacheControl: "3600",
      upsert: false,
    });
  if (!storageError) {
    const { data: projectsData, error: projectsError } = await supabase
      .from("projects")
      .insert([{ owner_id: ownerId }])
      .select("project_id");

    const { data: editorData, error: editorError } = await supabase
      .from("editor")
      .insert([{ owner_id: ownerId }])
      .select("editor_id");

    if (!projectsError && !editorError) {
      const { error: documentsError } = await supabase
        .from("documents")
        .insert([
          {
            document_name: fileName,
            document_path: path,
            owner_id: ownerId,
            project_id: projectsData[0]?.project_id,
            editor_id: editorData[0]?.editor_id,
          },
        ]);
      if (documentsError) {
        return documentsError;
      }
    } else {
      return projectsError || editorError;
    }
  } else {
    return storageError;
  }
};

export const uploadUrl = async (
  ownerId: string,
  fileName: string,
  url: string
) => {
  const { data: projectsData, error: projectsError } = await supabase
    .from("projects")
    .insert([{ owner_id: ownerId }])
    .select("project_id");

  const { data: editorData, error: editorError } = await supabase
    .from("editor")
    .insert([{ owner_id: ownerId }])
    .select("editor_id");
  if (!projectsError && !editorError) {
    const { error: documentsError } = await supabase.from("documents").insert([
      {
        document_name: fileName,
        document_url: url,
        owner_id: ownerId,
        project_id: projectsData[0]?.project_id,
        editor_id: editorData[0]?.editor_id,
      },
    ]);
    if (documentsError) {
      return documentsError;
    }
  } else {
    return projectsError || editorError;
  }
};

export const GetPersonalUrl = async (path: any, documentId: string) => {
  const { data, error } = await supabase.storage
    .from("pdfs")
    .createSignedUrl(path, 30 * 86400);
  if (!error) {
    const { error: docError } = await supabase
      .from("documents")
      .update({ document_url: data?.signedUrl })
      .eq("document_id", documentId)
      .select("document_url");
    if (!docError) {
      return data?.signedUrl;
    }
  }
};

export const loadHighlightsState = async (highlightsId: any) => {
  let { data, error } = await supabase
    .from("documents")
    .select("*")
    .eq("document_id", highlightsId);

  if (!error) {
    return data;
  }
};

export const loadCanvasState = async (canvasId: any) => {
  let { data, error } = await supabase
    .from("projects")
    .select("*")
    .eq("project_id", canvasId);
  if (!error) {
    return data;
  }
};

export const loadEditorState = async (editorId: any) => {
  let { data, error } = await supabase
    .from("editor")
    .select("*")
    .eq("editor_id", editorId);
  if (!error) {
    return data;
  }
};

export const changeEmail = async (userEmail: string) => {
  const { error } = await supabase.auth.updateUser({
    email: userEmail,
  });
  return !error;
};

export const changePassword = async (userPassword: string) => {
  const { error } = await supabase.auth.updateUser({
    password: userPassword,
  });
  return !error;
};

export const changeLastName = async (lastName: string, userId: string) => {
  const { data, error } = await supabase
    .from("profiles")
    .update({ last_name: lastName })
    .eq("user_id", userId)
    .select("last_name");
  if (!error) {
    return data[0]?.last_name;
  }
};

export const changeFirstName = async (firstName: string, userId: string) => {
  const { data, error } = await supabase
    .from("profiles")
    .update({ first_name: firstName })
    .eq("user_id", userId)
    .select("first_name");
  if (!error) {
    return data[0]?.first_name;
  }
};

export const setTimeLog = async (id: string | undefined, timeInput: number) => {
  const { data, error } = await supabase
    .from("documents")
    .update({ time_log: timeInput })
    .eq("document_id", id)
    .select("time_log");
  if (!error) {
    return data;
  }
};

export const deleteUserAndTheirData = async (userId: string): Promise<void> => {
  const { error: profileError } = await supabase
    .from("profiles")
    .delete()
    .eq("user_id", userId);

  if (profileError) {
    throw new Error(`Error deleting user: ${profileError.message}`);
  }

  const { error: editorError } = await supabase
    .from("editor")
    .delete()
    .eq("owner_id", userId);

  if (editorError) {
    throw new Error(`Error deleting editor data: ${editorError.message}`);
  }

  const { error: projectsError } = await supabase
    .from("projects")
    .delete()
    .eq("owner_id", userId);

  if (projectsError) {
    throw new Error(`Error deleting projects data: ${projectsError.message}`);
  }

  const { error: documentsError } = await supabase
    .from("documents")
    .delete()
    .eq("owner_id", userId);

  if (documentsError) {
    throw new Error(`Error deleting documents data: ${documentsError.message}`);
  }
  const { data: listOfFiles, error: filesError } = await supabase.storage
    .from("pdfs")
    .list(userId);
  if (!filesError) {
    const filesToRemove = listOfFiles.map((x) => `${userId}/${x.name}`);
    await supabase.storage.from("pdfs").remove(filesToRemove);
  }

  if (
    !profileError &&
    !editorError &&
    !projectsError &&
    !documentsError &&
    !filesError
  ) {
    await supabase.rpc("delete_user");
  }
};
