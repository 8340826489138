import { createSlice } from "@reduxjs/toolkit";

interface themeState {
  mode: string;
}

const initialState: themeState = {
  mode: "dark",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeMode: (state) => {
      state.mode === "light" ? (state.mode = "dark") : (state.mode = "light");
    },
  },
});

export const { changeMode } = themeSlice.actions;
export default themeSlice.reducer;
