import { Dispatch, SetStateAction, useEffect, useRef } from "react";

const useIntersectionObserver = (
  setActiveId: Dispatch<SetStateAction<string>>
) => {
  //Need to get right type for useRef
  const headingElementsRef = useRef<any>({});
  //Need to get rid of "any" in heading.reduce
  useEffect(() => {
    const callback = (headings: Array<IntersectionObserverEntry>) => {
      headingElementsRef.current = headings.reduce(
        (map: any, headingElement) => {
          map[headingElement.target.id] = headingElement;
          return map;
        },
        headingElementsRef.current
      );

      const visibleHeadings: Array<IntersectionObserverEntry> = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement: IntersectionObserverEntry =
          headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id: string) =>
        headingElements.findIndex((heading) => heading.id === id);

      const sortedVisibleHeadings = visibleHeadings.sort(
        (a, b) => getIndexFromId(a.target.id) - getIndexFromId(b.target.id)
      );
      const firstVisibleHeading = sortedVisibleHeadings[0];
      if (!firstVisibleHeading) return;
      setActiveId(firstVisibleHeading.target.id);
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px 0px -40% 0px",
    });

    const headingElements = Array.from(document.querySelectorAll("h2, h3"));

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

export default useIntersectionObserver;
