import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Updater, useImmer } from "use-immer";
import { useTheme } from "@mui/material";
import { Button, Link } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
interface Props {
  modals: (
    modalVisible: string,
    setModalVisible: Updater<string>
  ) => ReactNode[];
}

export default function Footer({ modals }: Props) {
  const [modalVisible, setModalVisible] = useImmer("");
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: "auto", bottom: 0, bgcolor: theme.palette.background.paper }}
    >
      <Toolbar>
        <Typography variant="h6" component="div">
          &copy; 2023 Annogram
        </Typography>
        <Link
          component="button"
          underline="hover"
          sx={{ marginLeft: "2em", color: theme.palette.text.primary }}
          onClick={() => navigate("/legal")}
        >
          Terms and Privacy
        </Link>
        <Button
          sx={{ color: theme.palette.text.primary, marginLeft: "auto" }}
          onClick={() => navigate("/documentation")}
        >
          Documentation
        </Button>
        <Button
          sx={{ color: theme.palette.text.primary }}
          onClick={() => setModalVisible("contact")}
        >
          Contact
        </Button>
        {modals(modalVisible, setModalVisible)}
      </Toolbar>
    </AppBar>
  );
}
