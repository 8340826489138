import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

export const CustomTextField = styled(TextField)({
  marginTop: 15,
  width: "100%",
  "& label.Mui-focused": {
    color: "#a792c9",
  },
});
