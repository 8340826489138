import { useState } from "react";
import Box from "@mui/material/Box";
import DocumentsTable from "../user-documents/DocumentsTable";
import PdfSourceModal from "../user-documents/PdfSourceModal";

const DocumentsScreen = () => {
  const [modalVisible, setModalVisible] = useState("");
  const [uploadProgress, setUploadProgress] = useState<boolean>(false);

  return (
    <Box>
      <DocumentsTable
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
      />
      <PdfSourceModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        uploadProgress={uploadProgress}
        setUploadProgress={setUploadProgress}
        key="choosePdf"
      />
    </Box>
  );
};

export default DocumentsScreen;
