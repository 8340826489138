import { Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AnnogramImage from "../assets/Annogram.png";

const GoodbyeScreen = () => {
  const navigate = useNavigate();

  const redirectToRegister = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        marginTop: "15px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={AnnogramImage}
            alt="annogram logo"
            style={{ width: "60%" }}
          />
        </Box>
        <Typography variant="h2" gutterBottom>
          We're sorry to see you go...
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you change your mind, you can always create a new account.
        </Typography>
        <Button
          onClick={redirectToRegister}
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Create a new account
        </Button>
      </Box>
    </Box>
  );
};

export default GoodbyeScreen;
