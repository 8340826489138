import { useForm } from "react-hook-form";
import { Button, Box } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomTextField } from "../reusable/CustomTextField";
import { emailSchema } from "../utilities/yupScehma";
import { useAppDispatch } from "../redux/reduxHooks";
import { resetPassword } from "../redux/authSlice";

interface MyForm {
  email: string;
}

const ForgotPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MyForm>({
    resolver: yupResolver(emailSchema),
  });
  const dispatch = useAppDispatch();

  const submitForm = (data: MyForm) => {
    dispatch(resetPassword(data.email));
  };

  return (
    <Box
      sx={{
        marginTop: "15px",
        marginX: "40px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <CustomTextField
          autoFocus
          {...register("email")}
          name="email"
          label="Email"
          error={!!errors.email}
          helperText={errors.email && errors?.email?.message}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default ForgotPasswordForm;
