import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import HeroVideo from "../assets/Hero_480.mp4";
import { useNavigate } from "react-router-dom";
import "../style/MainScreen.css";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Box className="main">
      <Box>
        <Typography variant="h1">AnnoGram</Typography>
        <Typography variant="h3">
          The easiest way to read, annotate, and diagram online.
        </Typography>
      </Box>
      <div className="container">
        <video className="video" autoPlay={true} loop={true} muted={true}>
          <source src={HeroVideo} type="video/mp4" />
        </video>
        <button className="btn" onClick={() => navigate("/annogram")}>
          Try Demo
        </button>
      </div>
    </Box>
  );
};

export default Hero;
