import { PropsWithChildren, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";

import type {
  Content,
  IHighlight,
  ScaledPosition,
} from "@argument-studio/react-pdf-highlighter-with-categories";
import {
  AreaHighlight,
  Highlight,
  PdfHighlighter,
  PdfLoader,
  Popup,
} from "@argument-studio/react-pdf-highlighter-with-categories";
import { addHighlight, updateHighlight } from "../redux/highlightsSlice";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tip from "./Tip";
import HighlightPopup from "./HighlightPopup";
import { v4 } from "uuid";

interface Props {
  isSidebarOpen: boolean;
  scale: string;
  destinationPage: number;
  getPageCount: (pageCount: number) => void;
  getCurrentPage: (page: number) => void;
  sidebarWidth: number;
  canvasOn: boolean;
}

const parseIdFromHash = (): string =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

const PdfViewer = ({
  scale,
  isSidebarOpen,
  destinationPage,
  getPageCount,
  getCurrentPage,
  sidebarWidth,
  children,
  canvasOn,
}: PropsWithChildren<Props>) => {
  const pdfHighlighterRef = useRef<PdfHighlighter<IHighlight>>(null);
  const { highlights, categories, url, data } = useAppSelector(
    (state) => state.highlights
  );
  const dispatch = useAppDispatch();

  let scrollViewerTo = (highlight: any) => {};
  /* pdfHighlighterRef.current is the child component. .scrollTo is its method.
  The removeEventlistener is to prevent many event listeners from piling up.
  The dependency array tells that a new listener should be attached
  when the state.highlights array is changed. */
  useEffect(() => {
    const scrollToHighlightFromHash = () => {
      const highlight = highlights.find(
        (highlight) => highlight.id === parseIdFromHash()
      );

      const highlighter = pdfHighlighterRef.current;

      if (highlight && highlighter) {
        highlighter.scrollTo(highlight);
      }
    };

    window.addEventListener("hashchange", scrollToHighlightFromHash);
    return () =>
      window.removeEventListener("hashchange", scrollToHighlightFromHash);
  }, [highlights]);

  function addNewHighlight(highlight: IHighlight) {
    dispatch(addHighlight(highlight));
  }

  function modifyHighlight(
    id: string,
    position: Partial<ScaledPosition>,
    content: Partial<Content>
  ) {
    dispatch(updateHighlight({ id, position, content }));
  }

  return (
    <div className={canvasOn ? "pdf-viewer-container--hidden" : ""}>
      <PdfLoader
        url={url}
        data={data}
        beforeLoad={
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              marginLeft: isSidebarOpen ? `${sidebarWidth / 2}px` : "",
            }}
          >
            <CircularProgress color="success" size={100} />
          </Box>
        }
      >
        {(pdfDocument) => (
          <PdfHighlighter
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "94vh",
              marginTop: "6vh",
              width: isSidebarOpen ? `calc(100vw - ${sidebarWidth}px)` : "",
              marginLeft: isSidebarOpen ? `${sidebarWidth}px` : "",
            }}
            categoryLabels={categories}
            pdfDocument={pdfDocument}
            enableAreaSelection={(event) => event.altKey}
            onScrollChange={resetHash}
            pdfScaleValue={scale}
            scrollRef={(scrollTo) => {
              scrollViewerTo = scrollTo;
            }}
            ref={pdfHighlighterRef}
            onSelectionFinished={(
              position,
              content,
              hideTipAndSelection,
              transformSelection,
              clabels
            ) => (
              <Tip
                onOpen={transformSelection}
                onConfirm={(comment) => {
                  addNewHighlight({
                    id: v4(),
                    content,
                    position,
                    comment,
                  });

                  hideTipAndSelection();
                }}
                categoryLabels={clabels}
              />
            )}
            highlightTransform={(
              highlight,
              index,
              setTip,
              hideTip,
              viewportToScaled,
              screenshot,
              isScrolledTo
            ) => {
              const isTextHighlight = !Boolean(
                highlight.content && highlight.content.image
              );

              const component = isTextHighlight ? (
                <Highlight
                  isScrolledTo={isScrolledTo}
                  position={highlight.position}
                  comment={highlight.comment}
                  categoryLabels={categories}
                />
              ) : (
                <AreaHighlight
                  categoryLabels={categories}
                  isScrolledTo={isScrolledTo}
                  highlight={highlight}
                  comment={highlight.comment}
                  onChange={(boundingRect) => {
                    modifyHighlight(
                      highlight.id,
                      { boundingRect: viewportToScaled(boundingRect) },
                      { image: screenshot(boundingRect) }
                    );
                  }}
                />
              );

              return (
                <Popup
                  popupContent={
                    <HighlightPopup selectedHighlight={highlight} />
                  }
                  onMouseOver={(popupContent) =>
                    setTip(highlight, () => popupContent)
                  }
                  onMouseOut={() => {
                    hideTip();
                  }}
                  key={index}
                  children={component}
                />
              );
            }}
            highlights={highlights}
            getPageCount={getPageCount}
            getCurrentPage={getCurrentPage}
            destinationPage={destinationPage}
          />
        )}
      </PdfLoader>
      {children}
    </div>
  );
};

export default PdfViewer;
