import { useCallback, useState } from "react";
import { useImmer } from "use-immer";
import SidebarListItem from "./SidebarListItem";
import { sortHighlightsByCategoryAndLocation } from "../utilities/utils";
import { useAppSelector } from "../redux/reduxHooks";
import { Box, Drawer, IconButton, Typography, useTheme } from "@mui/material";

import { ChevronLeft, LastPage, FirstPage } from "@mui/icons-material";

import "../style/Sidebar.css";

import FilterCategories from "./FilterCategories";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import ResizableHandle from "./ResizableHandle";
import SidebarContextMenu from "./SidebarContextMenu";

interface Props {
  handleHighlightDragStart: (id: string) => () => void;
  isNonMobile: boolean;
  drawerWidth: string;
  isSidebarOpen: boolean;
  closeSidebar: () => void;
  pageCount: number;
  handlePageIncrease: () => void;
  handlePageDecrease: () => void;
  currentPage: number;
  handlePageChange: (page: number) => void;
  setSidebarWidth: (width: number) => void;
  canvasOn: boolean;
}

export default function ArgSidebar({
  isSidebarOpen,
  closeSidebar,
  isNonMobile,
  drawerWidth,
  pageCount,
  handlePageDecrease,
  handlePageIncrease,
  currentPage,
  handlePageChange,
  setSidebarWidth,
  handleHighlightDragStart,
  canvasOn,
}: Props) {
  const theme = useTheme();

  const { highlights, categories } = useAppSelector(
    (state) => state.highlights
  );

  const [categoryFilter, setCategoryFilter] = useState("None");
  const [coords, setCoords] = useImmer({ x: 0, y: 0 });
  const [contextMenuId, setContextMenuId] = useState<string>("");

  const handleHighlightContextMenu =
    (id: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setContextMenuId(id);
      setCoords({ x: event.clientX + 2, y: event.clientY - 6 });
    };

  const handleCloseContextMenu = useCallback(() => setContextMenuId(""), []);

  const sortedHighlights = sortHighlightsByCategoryAndLocation(
    highlights,
    categories
  );

  return (
    <Drawer
      open={isSidebarOpen}
      onClose={closeSidebar}
      variant="persistent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          borderWidth: isNonMobile ? 0 : "2px",
          width: drawerWidth,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            margin: "auto",
            visibility: canvasOn ? "hidden" : "visible",
          }}
        >
          <Tooltip title="First Page">
            <IconButton onClick={handlePageDecrease}>
              <FirstPage />
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: 1,
            }}
          >
            <TextField
              sx={{ width: "100px" }}
              label={currentPage}
              inputProps={{ min: 1, max: pageCount }}
              variant="outlined"
              type="number"
              size="small"
              onFocus={(e) => {
                e.target.value = "";
              }}
              onChange={(e) => {
                e.preventDefault();
                handlePageChange(parseInt(e.target.value));
              }}
            />
            <Typography
              color={theme.palette.text.primary}
              sx={{ marginLeft: "10px" }}
            >
              of {pageCount}
            </Typography>
          </Box>
          <Tooltip title={"Last Page"}>
            <IconButton onClick={handlePageIncrease}>
              <LastPage />
            </IconButton>
          </Tooltip>
        </Box>
        <Tooltip title="Close Sidebar">
          <IconButton onClick={closeSidebar} sx={{ justifySelf: "end" }}>
            <ChevronLeft />
          </IconButton>
        </Tooltip>
      </Box>
      <FilterCategories setCategoryFilter={setCategoryFilter} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          height: "100vh",
        }}
      >
        <Box sx={{ width: "97vw" }}>
          {sortedHighlights.map((highlight) => (
            <SidebarListItem
              categoryFilter={categoryFilter}
              highlight={highlight}
              key={highlight.id}
              categories={categories}
              handleContextMenu={handleHighlightContextMenu(highlight.id)}
              handleDragStart={handleHighlightDragStart(highlight.id)}
            />
          ))}
        </Box>
        <ResizableHandle
          setSidebarWidth={setSidebarWidth}
          closeSidebar={closeSidebar}
        />
      </Box>
      {contextMenuId && (
        <SidebarContextMenu
          coords={coords}
          menuTargetId={contextMenuId}
          closeMenu={handleCloseContextMenu}
        />
      )}
    </Drawer>
  );
}
