import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  SyntheticEvent,
  useState,
} from "react";
import Button from "@mui/material/Button";

import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

import { useAppSelector } from "../redux/reduxHooks";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Grid2 from "@mui/material/Unstable_Grid2";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { uploadFile, uploadUrl } from "../services/supabaseAPI";
import { LinearProgress, useTheme } from "@mui/material";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

interface Props {
  modalVisible: string;
  setModalVisible: Dispatch<SetStateAction<string>>;
  uploadProgress: boolean;
  setUploadProgress: Dispatch<SetStateAction<boolean>>;
}

const PdfSourceModal = ({
  modalVisible,
  setModalVisible,
  uploadProgress,
  setUploadProgress,
}: Props) => {
  const [url, setUrl] = useState<string>("");
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>("");
  const [docName, setDocName] = useState<string>("");
  const [expanded, setExpanded] = useState<string | false>(false);

  const theme = useTheme();
  const userState = useAppSelector((state) => state.user);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setDocName("");
      setUrl("");
    };
  const handleClose = () => {
    setModalVisible("");
    setUrl("");
    setDocName("");
    setFileName("");
    setExpanded(false);
    setUploadProgress(false);
  };

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setUploadProgress(true);

    if (userState.userId) {
      if (!file) {
        const urlError = await uploadUrl(
          userState.userId,
          docName ? docName : url,
          url
        );
        if (!urlError) {
          handleClose();
        }
      } else {
        const fileError = await uploadFile(
          userState.userId,
          docName ? docName : fileName,
          file
        );
        if (!fileError) {
          handleClose();
        }
      }
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setFile(file);
    setFileName(file.name.slice(0, 33));
  };

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const handleDocNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDocName(e.target.value);
  };

  return (
    <Dialog
      open={modalVisible === "choosePdf"}
      onClose={handleClose}
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          marginLeft: "20px",
          marginTop: "40px",
        },
        "& .MuiDialog-paper": {
          background: theme.palette.background.paper,
        },
      }}
    >
      <Box display="flex" alignItems="center">
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 0, top: 1 }}
        >
          <Close />
        </IconButton>
        <DialogTitle sx={{ margin: "auto" }} fontSize={36}>
          {uploadProgress ? "Document Uploading" : "Upload Document"}
        </DialogTitle>
      </Box>
      <Divider />
      {uploadProgress ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <DialogContent>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>From URL</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                sx={{ width: "250px" }}
                multiline={true}
                type="text"
                value={url}
                placeholder="https://arxiv.com/example.pdf"
                onChange={handleUrlChange}
              />
              <TextField
                sx={{ width: "250px", marginLeft: "5px" }}
                type="text"
                placeholder="Document Title"
                value={docName}
                multiline={true}
                onChange={handleDocNameChange}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>From Local File</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                style={{
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              />

              <TextField
                sx={{ width: "250px", marginLeft: "5px" }}
                type="text"
                placeholder={fileName ?? "Document Title"}
                value={docName}
                multiline={true}
                onChange={handleDocNameChange}
              />
            </AccordionDetails>
          </Accordion>
        </DialogContent>
      )}
      <Divider />
      <Grid2
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "0.5rem",
          marginY: "10px",
        }}
      >
        <Button variant="contained" type="submit" onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
      </Grid2>
    </Dialog>
  );
};

export default PdfSourceModal;
