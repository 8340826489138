import { Dispatch, SetStateAction, useState } from "react";
import { useAppSelector } from "../redux/reduxHooks";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface Props {
  setCategoryFilter: Dispatch<SetStateAction<string>>;
}

const FilterCategories = ({ setCategoryFilter }: Props) => {
  const { categories } = useAppSelector((state) => state.highlights);
  const [labelCategory, setLabelCategory] = useState("");

  const handleSelectedCategory = (event: SelectChangeEvent) => {
    setCategoryFilter(event.target.value);
    setLabelCategory(event.target.value);
  };
  return (
    <FormControl fullWidth sx={{ p: 2, marginX: "auto", maxWidth: "400px" }}>
      <Select value={labelCategory} onChange={handleSelectedCategory}>
        <MenuItem value="None">None</MenuItem>
        {categories.map((category) => (
          <MenuItem key={category.label} value={category.label}>
            {category.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>Filter Categories</FormHelperText>
    </FormControl>
  );
};

export default FilterCategories;
