import { useCallback, useEffect, useRef } from "react";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { EditorState } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import { setEditorState } from "../../redux/editorSlice";

export default function HandleChangePlugin() {
  const [editor] = useLexicalComposerContext();
  const editorState = useAppSelector((state) => state.editor.editorState);
  const dispatch = useAppDispatch();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      if (editorState) {
        editor.setEditorState(editor.parseEditorState(editorState));
      }
    }
  }, [isFirstRender, editorState, editor]);

  const onChange = useCallback(
    (lexicalState: EditorState) => {
      dispatch(setEditorState(JSON.stringify(lexicalState.toJSON())));
    },
    [dispatch]
  );

  return <OnChangePlugin onChange={onChange} ignoreSelectionChange={true} />;
}
