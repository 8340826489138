import Konva from "konva";
import { Stage } from "konva/lib/Stage";
import { DragEvent, RefObject } from "react";
import { Layer } from "react-konva";
import { addRectangle } from "../redux/canvasSlice";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { calculateTrimmedTextSize } from "../utilities/utils";
import ArrowContextMenu from "./ArrowContextMenu";
import CanvasRectangle from "./CanvasRectangle";
import CanvasStage from "./CanvasStage";
import RectangleContextMenu from "./RectangleContextMenu";

interface Props {
  draggedHighlightId: string;
  clearDraggedHighlightId: () => void;
  canvasOn: boolean;
  stageRef: RefObject<Konva.Stage>;
}

const Canvas = ({
  draggedHighlightId,
  clearDraggedHighlightId,
  canvasOn,
  stageRef,
}: Props) => {
  const dispatch = useAppDispatch();
  const { highlights } = useAppSelector((state) => state.highlights);
  const { rectangles } = useAppSelector((state) => state.canvas);

  function handleDrop(
    event: DragEvent<HTMLDivElement>,
    stage: Stage | null,
    draggedHighlightId: string
  ) {
    event.preventDefault();

    if (!draggedHighlightId || !stage) return;

    const highlight = highlights.find(
      (highlight) => highlight.id === draggedHighlightId
    );

    if (!highlight) return;

    stage.setPointersPositions(event);

    const pointerPosition = stage.getPointerPosition();

    if (!pointerPosition) return;

    const { text, image } = highlight.content;
    const { category } = highlight.comment;
    const { x1, x2, y1, y2 } = highlight.position.boundingRect;
    const { x, y } = pointerPosition;

    dispatch(
      addRectangle({
        x,
        y,
        category,
        text,
        image,
        expanded: false,
        tag: "",
        ...(text
          ? calculateTrimmedTextSize(text)
          : { width: x2 - x1, height: y2 - y1 }),
        arrows: [],
      })
    );
    clearDraggedHighlightId();
  }

  return (
    <div
      className={`argument-canvas-container ${
        !canvasOn ? "argument-canvas-container--hidden" : ""
      }`}
      onDrop={(e) => handleDrop(e, stageRef.current, draggedHighlightId)}
      onDragOver={(e) => e.preventDefault()}
    >
      <CanvasStage stageRef={stageRef}>
        <Layer name="arrow-layer" />
        <Layer>
          {rectangles.map((_, idx) => (
            <CanvasRectangle key={"rectangle" + idx} idx={idx} />
          ))}
        </Layer>
        <Layer name="dragging-layer" />
      </CanvasStage>
      <ArrowContextMenu />
      <RectangleContextMenu />
    </div>
  );
};

export default Canvas;
