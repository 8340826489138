import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CanvasAuxState } from "../types/canvasTypes";
import { Vector2d } from "konva/lib/types";
import { RootState } from "./store";

const initialState: CanvasAuxState = {
  draggedRectIdx: null,
  arrowContextMenuIdx: null,
  rectContextMenuIdx: null,
  coords: { x: 0, y: 0 },
};

export const canvasAuxSlice = createSlice({
  name: "canvasAux",
  initialState,
  reducers: {
    endDragRectangle: (draft: CanvasAuxState) => {
      draft.draggedRectIdx = null;
    },
    startDragRectangle: (
      draft: CanvasAuxState,
      action: PayloadAction<{ idx: number }>
    ) => {
      draft.draggedRectIdx = action.payload.idx;
    },
    openArrowContextMenu: (
      draft: CanvasAuxState,
      action: PayloadAction<{ idx: [number, number] }>
    ) => {
      draft.arrowContextMenuIdx = action.payload.idx;
    },
    setCoords: (draft: CanvasAuxState, action: PayloadAction<Vector2d>) => {
      draft.coords = action.payload;
    },
    closeArrowContextMenu: (draft: CanvasAuxState) => {
      draft.arrowContextMenuIdx = null;
    },
    openRectContextMenu: (
      draft: CanvasAuxState,
      action: PayloadAction<{ idx: number }>
    ) => {
      draft.rectContextMenuIdx = action.payload.idx;
    },
    closeRectContextMenu: (draft: CanvasAuxState) => {
      draft.rectContextMenuIdx = null;
    },
  },
});

export const {
  startDragRectangle,
  endDragRectangle,
  openArrowContextMenu,
  closeArrowContextMenu,
  openRectContextMenu,
  closeRectContextMenu,
  setCoords,
} = canvasAuxSlice.actions;

export const selectCanvas = (state: RootState) => state.canvasAux;

export default canvasAuxSlice.reducer;
