import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import MainNavbar from "./MainNavbar";
import Footer from "./Footer";
import AuthenticationModal from "./AuthenticationModal";
import ContactModal from "./ContactModal";

const Landing = () => {
  return (
    <Box width="100%" height="100%">
      <Box>
        <MainNavbar
          modals={(modalVisible, setModalVisible) => [
            <AuthenticationModal
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              key="authentication"
            />,
            <ContactModal
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              key="contact"
            />,
          ]}
        />
        <Outlet />
        <Footer
          modals={(modalVisible, setModalVisible) => [
            <ContactModal
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              key="contact"
            />,
          ]}
        />
      </Box>
    </Box>
  );
};

export default Landing;
