import { Dispatch, SetStateAction } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";

interface Props {
  modalVisible: string;
  setModalVisible: Dispatch<SetStateAction<string>>;
  warningText: string;
  modalType: string;
  reset: () => void;
}

const ResetWarningModal = ({
  modalVisible,
  setModalVisible,
  warningText,
  modalType,
  reset,
}: Props) => {
  const handleCancel = () => setModalVisible("");
  const theme = useTheme();

  const handleReset = () => {
    reset();
    setModalVisible("");
  };

  return (
    <Dialog
      open={modalVisible === modalType}
      onClose={handleCancel}
      aria-labelledby="reset-dialog-title"
      aria-describedby="reset-the-canvas"
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",

          marginTop: "30vh",
          "& .MuiDialog-paper": {
            background: theme.palette.background.paper,
          },
        },
      }}
    >
      <DialogTitle>{"Warning!"}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{warningText}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={handleReset}>
          Reset
        </Button>
        <Button variant="contained" onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetWarningModal;
