import React from "react";
import { Typography, Container } from "@mui/material";
import Link from "@mui/material/Link";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        AnnoGram Privacy Policy
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Last Updated: June 6, 2023
      </Typography>
      <Typography variant="body1" paragraph>
        This Privacy Policy describes the practices of AnnoGram regarding the
        collection, use, and disclosure of your information. By accessing or
        using our website or services, you agree to the practices outlined in
        this Privacy Policy.
      </Typography>
      <Typography variant="h5" gutterBottom>
        1. Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        We may collect and store the following types of information:
      </Typography>
      <Typography variant="body1" paragraph>
        <ul>
          <li>
            Direct Identifiers: Such as your name, username, email address,
            billing address, phone number, and debit/credit card numbers.
          </li>
          <li>Job-related Information: Including your job title.</li>
          <li>Contact Preferences and Authentication Data.</li>
          <li>Sensitive Personal Data: Including student data.</li>
          <li>
            User-generated content: Such as diagrams, annotations, and any other
            content you upload to our services.
          </li>
          <li>
            Social Media Data: If you register for our service using a social
            media account, we may receive information from that service, such as
            your username and other information you choose to make available.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" gutterBottom>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use your information to:
      </Typography>
      <Typography variant="body1" paragraph>
        <ul>
          <li>Provide, operate, maintain, and improve our services.</li>
          <li>Process transactions.</li>
          <li>Send you service-related communications.</li>
          <li>Respond to your inquiries and provide customer service.</li>
          <li>Enhance the security of our services.</li>
        </ul>
      </Typography>
      <Typography variant="body1" paragraph>
        We do not share your information with third-party advertisers.
      </Typography>
      <Typography variant="h5" gutterBottom>
        3. Sharing Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We do not sell, trade, rent, or disclose your personal information to
        any third parties under any circumstances. Our focus remains on
        safeguarding your personal information and using it strictly for the
        purpose it was collected for, as per your consent.
      </Typography>
      <Typography variant="h5" gutterBottom>
        4. Security
      </Typography>
      <Typography variant="body1" paragraph>
        We implement reasonable security measures designed to protect your
        information from unauthorized access, disclosure, alteration, or
        destruction. However, no transmission over the internet or method of
        electronic storage is 100% secure; as such, while we strive to use
        commercially acceptable means to protect your personal information, we
        cannot guarantee its absolute security.
      </Typography>

      <Typography variant="h5" gutterBottom>
        5. Children's Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Our service is not intended for children under the age of 13. If we
        learn that we have collected personal information from a child under age
        13, we will delete that information as quickly as possible.
      </Typography>

      <Typography variant="h5" gutterBottom>
        6. Your Privacy Rights
      </Typography>
      <Typography variant="body1" paragraph>
        You have the right to access, correct, update, or request deletion of
        your personal information. You can do so at any time by contacting us at
        the information provided below.
      </Typography>

      <Typography variant="h5" gutterBottom>
        7. Changes to this Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page and
        updating the "last updated" date at the top of this page.
      </Typography>

      <Typography variant="h5" gutterBottom>
        8. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at{" "}
        <Link color="#50c878" href="mailto:support@annogram.io">
          support@annogram.io
        </Link>
      </Typography>
      <Typography variant="body1" paragraph>
        This Privacy Policy applies to all information collected through our
        services, whether submitted by you or automatically collected, both
        online and offline, and includes all related services provided to you.
      </Typography>
      <Typography variant="body1" paragraph>
        By using the services, you consent to all actions taken by us concerning
        your information in compliance with this Privacy Policy.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
