import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { supabase } from "../utilities/supabaseClient";
import { userState } from "../types/types";

interface AuthState {
  userInfo: userState | null | undefined;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
  isForgotPassword: boolean;
}

const initialState: AuthState = {
  userInfo: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isForgotPassword: false,
};

//Need to change any here
export const signup = createAsyncThunk(
  "auth/signup",
  async (signUp: any, thunkAPI) => {
    const { data, error } = await supabase.auth.signUp({
      email: signUp.email,
      password: signUp.password,
      options: {
        data: {
          first_name: signUp.firstName,
          last_name: signUp.lastName,
        },
      },
    });
    if (error) {
      return thunkAPI.rejectWithValue(error.message);
    } else if (data?.user?.identities?.length === 0) {
      return thunkAPI.rejectWithValue("Someone with that email already exits");
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (user: any, thunkAPI) => {
    const { error } = await supabase.auth.signInWithPassword({
      email: user.email,
      password: user.password,
    });
    if (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (email: string, thunkAPI) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://annogram.io/resetpassword",
    });
    if (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const logout = createAsyncThunk("auth/logout", async () => {
  return await supabase.auth.signOut();
});

export const addUserInfo = createAsyncThunk(
  "auth/addUser",
  async (userId: string) => {
    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("user_id", userId);
    if (!error) {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      return { ...data[0], email: session?.user.email };
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.isForgotPassword = false;
    },
    clearAll: () => {},

    setError: (state, action) => {
      state.isError = action.payload;
    },

    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signup.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(signup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string; // Fix this
      })
      .addCase(logout.fulfilled, (state) => {
        state.userInfo = null;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string; // Fix this
      })
      .addCase(addUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isForgotPassword = true;
      });
  },
});

export const { reset, clearAll, setError, setLoading } = authSlice.actions;
export default authSlice.reducer;
