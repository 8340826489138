import { Dispatch, SetStateAction } from "react";
import ContactForm from "../reusable/ContactForm";
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  modalVisible: string;
  setModalVisible: Dispatch<SetStateAction<string>>;
}

const ContactModal = ({ modalVisible, setModalVisible }: Props) => {
  const theme = useTheme();
  const handleCloseLogIn = () => setModalVisible("");

  return (
    <Dialog
      open={modalVisible === "contact"}
      onClose={handleCloseLogIn}
      sx={{
        "& .MuiDialog-paper": {
          background: theme.palette.background.paper,
        },
      }}
    >
      <AppBar
        sx={{
          position: "relative",
          background: theme.palette.background.default,
        }}
      >
        <Toolbar>
          <Typography
            sx={{ ml: 2, flex: 1, textAlign: "center" }}
            variant="h2"
            component="div"
          >
            Contact Us
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseLogIn}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ContactForm setModalVisible={setModalVisible} />
    </Dialog>
  );
};

export default ContactModal;
