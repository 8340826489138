import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import { useState } from "react";
import { Group, Label, Rect, Tag, Text } from "react-konva";
import { Portal } from "react-konva-utils";
import {
  endDragRectangle,
  openRectContextMenu,
  startDragRectangle,
} from "../redux/canvasAuxSlice";
import {
  addArrow,
  doubleArrow,
  moveRectangle,
  toggleRectangleExpansion,
} from "../redux/canvasSlice";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { ICanvasArrow, Rectangle } from "../types/canvasTypes";
import { Category } from "../types/types";
import {
  canvasDarkText,
  canvasTagFontFamily,
  canvasTextFontFamily,
  canvasTextFontSize,
  charLimit,
  textRectPadding,
} from "../utilities/consts";
import {
  calculateExpandedTextSize,
  calculateTrimmedTextSize,
  rectsHaveIntersection,
} from "../utilities/utils";
import CanvasArrow from "./CanvasArrow";
import CanvasImage from "./CanvasImage";

const findCategoryColor = (categories: Category[], rectangle: Rectangle) => {
  return categories.find((category) => category.label === rectangle.category)
    ?.background;
};

interface CanvasRectangleProps {
  idx: number;
}
const CanvasRectangle = ({ idx }: CanvasRectangleProps) => {
  const [originCoords, setOriginCoords] = useState<Vector2d>({ x: 0, y: 0 });
  const rectangles = useAppSelector((state) => state.canvas.rectangles);
  const viewerState = useAppSelector((state) => state.highlights);
  const themeState = useAppSelector((state) => state.theme.mode);
  const { draggedRectIdx } = useAppSelector((state) => state.canvasAux);
  const dispatch = useAppDispatch();

  const rectangle = rectangles[idx];
  if (!rectangle) return null;

  const { text, expanded, image, x, y, width, height, tag, arrows } = rectangle;

  const isDragged = draggedRectIdx === idx;

  if (!image && !text) return null;

  const handleContextMenu = (event: Konva.KonvaEventObject<MouseEvent>) => {
    event.evt.preventDefault();
    dispatch(openRectContextMenu({ idx }));
  };

  const handleRectangleGroupDragStart = (
    event: Konva.KonvaEventObject<DragEvent>
  ) => {
    const x = event.target.x();
    const y = event.target.y();
    dispatch(startDragRectangle({ idx }));
    dispatch(moveRectangle({ x, y, idx }));
    setOriginCoords({ x, y });
  };

  const handleRectangleGroupDragMove = (
    event: Konva.KonvaEventObject<DragEvent>
  ) => {
    dispatch(
      moveRectangle({
        x: event.target.x(),
        y: event.target.y(),
        idx,
      })
    );
  };

  function addArrows(idx: number, targetIds: number[]) {
    for (let i = 0; i < targetIds.length; i++) {
      const targetId = targetIds[i];
      if (targetId === undefined) continue;
      const hasArrow = rectangles[idx]?.arrows.find(
        ({ endRectIdx: end }) => end === targetId
      );

      if (hasArrow) continue;

      const hasReverseArrow = rectangles[targetId]?.arrows.find(
        ({ endRectIdx: end }) => end === idx
      );

      if (hasReverseArrow) {
        dispatch(doubleArrow({ startRectIdx: targetId, endRectIdx: idx }));
        continue;
      }
      dispatch(
        addArrow({
          startRectIdx: idx,
          endRectIdx: targetId,
          dash: false,
          double: false,
          tag: "",
        })
      );
    }
  }

  const getOverlappedObjectIdx = (startRectIdx: number) => {
    const startRect = rectangles[startRectIdx];
    if (!startRect) return [];
    return rectangles.reduce((acc: number[], endRect, endRectIdx): number[] => {
      if (!endRect || endRectIdx === startRectIdx) return acc;
      if (rectsHaveIntersection(startRect, endRect)) {
        acc.push(endRectIdx);
      }

      return acc;
    }, []);
  };

  const handleRectangleGroupDragEnd = (
    event: Konva.KonvaEventObject<DragEvent>
  ) => {
    const overlappedIndices = getOverlappedObjectIdx(idx);
    // we need to complete the move if there are no overlaps
    if (!overlappedIndices.length) {
      dispatch(endDragRectangle());
      dispatch(
        moveRectangle({
          x: event.target.x(),
          y: event.target.y(),
          idx,
        })
      );
      return;
    }

    const { x, y } = originCoords;

    // animation to original position is needed for state updating reasons
    event.target.to({
      x,
      y,
      duration: 0,
    });

    addArrows(idx, overlappedIndices);
    dispatch(endDragRectangle());
    dispatch(
      moveRectangle({
        x,
        y,
        idx,
      })
    );
  };

  const handleRectangleGroupDoubleClick = () => {
    if (!text) return;

    dispatch(
      toggleRectangleExpansion({
        idx,
        ...(expanded
          ? calculateTrimmedTextSize(text)
          : calculateExpandedTextSize(text)),
      })
    );
  };

  const { dragged, immobile } = sortArrowsIntoBuckets(
    arrows,
    isDragged,
    draggedRectIdx
  );

  return (
    <>
      <Portal selector=".dragging-layer" enabled={isDragged}>
        <Group
          draggable
          x={x}
          y={y}
          key={idx}
          onDragStart={handleRectangleGroupDragStart}
          onDragMove={handleRectangleGroupDragMove}
          onDragEnd={handleRectangleGroupDragEnd}
          onContextMenu={handleContextMenu}
        >
          <Rect
            fill={themeState === "dark" ? "rgb(40, 40, 40)" : "rgb(249, 248, 246)"}
            stroke={
              findCategoryColor(viewerState.categories, rectangle) || "#ddcc77"
            }
            strokeWidth={2.5}
            shadowEnabled={isDragged}
            shadowOffset={{ x: 3, y: 3 }}
            shadowOpacity={0.25}
            width={width}
            height={height}
            cornerRadius={5}
          />
          {text ? (
            <Text
              text={
                expanded
                  ? text
                  : text.slice(0, charLimit) +
                    (text.length >= charLimit ? "…" : "")
              }
              align="left"
              fontFamily={canvasTextFontFamily}
              fontSize={canvasTextFontSize}
              fill={themeState === "dark" ? "white" : canvasDarkText}
              verticalAlign="middle"
              width={width}
              height={height}
              padding={textRectPadding}
              onDblClick={handleRectangleGroupDoubleClick}
            />
          ) : image ? (
            <CanvasImage dataurl={image} />
          ) : null}
          <Label offsetY={18}>
            <Tag
              fill={
                tag
                  ? findCategoryColor(viewerState.categories, rectangle) ||
                    "#ddcc77"
                  : "transparent"
              }
              cornerRadius={[2, 2, 2, 2]}
            />
            <Text
              text={tag}
              fontFamily={canvasTagFontFamily}
              fill={canvasDarkText}
              padding={2}
            />
          </Label>
        </Group>
      </Portal>
      <Portal selector=".dragging-layer" enabled={dragged.length > 0}>
        {dragged.map(({ endRectIdx, ...rest }) => (
          <CanvasArrow
            startRectIdx={idx}
            endRectIdx={endRectIdx}
            key={idx + "." + endRectIdx}
            {...rest}
          />
        ))}
      </Portal>
      <Portal selector=".arrow-layer" enabled>
        {immobile.map(({ endRectIdx, ...rest }) => (
          <CanvasArrow
            startRectIdx={idx}
            endRectIdx={endRectIdx}
            key={idx + "." + endRectIdx}
            {...rest}
          />
        ))}
      </Portal>
    </>
  );
};

interface ArrowRenderBuckets {
  dragged: ICanvasArrow[];
  immobile: ICanvasArrow[];
}
/**
 * Sorting arrows into buckets enables the dragged arrows to be rendered
 * on top of immobile rectangles. It should(?) also bring some performance benefits.
 * @param arrows neighbors of the rectangle
 * @param isDragged whether the rectangle is being dragged
 * @param draggedRectIdx which rectangle is being dragged
 * @returns two buckets of arrows
 */
const sortArrowsIntoBuckets = (
  arrows: ICanvasArrow[],
  isDragged: boolean,
  draggedRectIdx: number | null
): ArrowRenderBuckets => {
  if (draggedRectIdx === null)
    return {
      dragged: [],
      immobile: arrows.filter((arrow) => !arrow.doNotRender),
    };

  if (isDragged)
    return {
      dragged: arrows.filter((arrow) => !arrow.doNotRender),
      immobile: [],
    };

  return arrows.reduce<ArrowRenderBuckets>(
    (acc, arrow) => {
      const { endRectIdx, doNotRender } = arrow;
      if (doNotRender) return acc;
      if (draggedRectIdx === endRectIdx) {
        acc.dragged.push(arrow);
      } else {
        acc.immobile.push(arrow);
      }
      return acc;
    },
    { dragged: [], immobile: [] }
  );
};

export default CanvasRectangle;
