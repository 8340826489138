import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface userState {
  userId: string;

}

const initialState: userState = {
  userId: "",
};

export const userSlice = createSlice({
  name: "persistence",
  initialState,
  reducers: {
    updateUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },

  },
 
});

export const { updateUserId } = userSlice.actions;
export default userSlice.reducer;
